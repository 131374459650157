import { styled } from "../shared/lib/styles";

export const Scrollable = styled("div", {
  overflow: "scroll",
  height: "600px",
});

export const Container = styled("div", {
  "@tablet": {
    width: "85%",
  },
});
