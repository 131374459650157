
interface ConnectWalletButtonProps {
  text: string;
  clicked: () => void;
}

export function MyButton({ clicked, text }: ConnectWalletButtonProps) {
  return (
    <button style={{ minHeight: 35 }} type="button" className="w-[150px] border-2 rounded" onClick={clicked}>
      {text}
    </button>
  );
}
