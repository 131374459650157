import { SendTransactionRequest, TonConnect, UserRejectsError, WalletInfo, WalletInfoInjected } from "@tonconnect/sdk";

import { isMobile, openLink } from "./utils";

const dappMetadata = { manifestUrl: "https://tonbyte.com/tonconnect-manifest.json" };

export const connector = new TonConnect(dappMetadata);

export function addReturnStrategy(url: string, returnStrategy: "back" | "none"): string {
  const link = new URL(url);

  link.searchParams.append("ret", returnStrategy);

  return link.toString();
}

export async function sendTransaction(tx: SendTransactionRequest, wallet: WalletInfo): Promise<{ boc: string }> {
  try {
    if ("universalLink" in wallet && !(wallet as WalletInfoInjected).embedded && isMobile()) {
      openLink(addReturnStrategy(wallet.universalLink, "none"), "_blank");
    }

    console.log(`ready to send ${tx}`);
    const result = await connector.sendTransaction(tx);
    console.log(`Send tx result: ${JSON.stringify(result)}`);

    return result;
  } catch (e) {
    let message = "Send transaction error";

    if (typeof e === "object" && e instanceof UserRejectsError) {
      message = "You rejected the transaction. Please try again and confirm transaction in your wallet.";
    }

    console.log("Send tx error:", message, e);
    throw message;
  }
}
