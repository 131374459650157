import { codepen, CopyBlock } from "react-code-blocks";
import { useTranslation } from "react-i18next";

import * as S from "./style";
import { Container } from "../../shared/lib/styles";

export function DocsPage() {
  const { t } = useTranslation("docs");
  return (
    <Container className="text-lg container place-content-center mx-auto lg:my-8">
      <S.TitleH2 className="text-xl lg:my-8" id="articles">
        {t("API")}:
      </S.TitleH2>
      <div className="contatiner mx-auto">
        <br />
        <p>
          *{" "}
          <b>
            <a href="/swagger" target="_blank" rel="noreferrer">
              {t("Swagger")}
            </a>
          </b>
        </p>
        <br />
        <S.TitleH2 className="text-xl lg:my-8" id="articles">
          {t("Articles")}
        </S.TitleH2>
        <p>
          *{" "}
          <b>
            <a href="https://telegra.ph/Host-static-TON-site-in-TON-Storage-03-19" target="_blank" rel="noreferrer">
              {t("HostStaticTON")}
            </a>
          </b>
        </p>
        <br />

        <hr />
        <S.TitleH2 className="text-xl my-6 lg:my-8" id="gateway">
          {t("Gateway")}
        </S.TitleH2>

        <div>
          <p>
            {t("Use")} <b>GET: /gateway/:bagID</b> {t("forWholeBag")}
          </p>
          <br />
          <CopyBlock
            lanshguage="url"
            text="https://tonbyte.com/gateway/D267F3028A2A214B9CB3F9253C9A3851A356AB0AB7B84C897B25CF3212813F4F"
            showLineNumbers={false}
            theme={codepen}
            wrapLines
            codeBlock
          />
          <br />
          <br />
          <p>
            {t("Or")} <b>GET: /gateway/:bagID/:fileName</b> {t("toGetFile")}
          </p>
          <br />
          <CopyBlock
            lanshguage="url"
            text="https://tonbyte.com/gateway/6BCDC821A0A0197B673F3F9FF97548463765013867B876996BCB5B6801C56DA9/bavly2.jpg"
            showLineNumbers={false}
            theme={codepen}
            wrapLines
            codeBlock
          />
        </div>
      </div>
    </Container>
  );
}
