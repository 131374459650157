import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { BagShortInfo } from "../../../models";
import { TonProofMSApi } from "../../../TonProofMSApi";
import { FileInfoModal } from "../FileInfoModal";
import * as S from "./style";
import { bytesToSize } from "../../../shared/lib/utils";

const media = [
  ".jpeg",
  ".jpg",
  ".png",
  ".gif",
  ".tiff",
  ".psd",
  ".webp",
  ".webm",
  ".mp4",
  ".mov",
  ".wmv",
  ".avi",
  ".avchd",
  ".mkv",
  ".webm",
  ".mp3",
  ".ogg",
  ".wav",
  ".wma",
];
const archives = [
  ".zip",
  ".tar.gz",
  ".tar.bz2",
  ".tar.Z",
  ".tar.xz",
  ".gz",
  ".bz2",
  ".Z",
  ".xz",
  ".apk",
  ".rar",
  ".7z",
  ".cab",
];
const text = [
  ".txt",
  ".pdf",
  ".doc",
  ".upd",
  ".xls",
  ".log",
  ".xml",
  ".html",
  ".json",
];
const directory = ["dir"];

interface UserFileProps {
  userProductId: number;
  userProductSecret: string;
  file: BagShortInfo;
  removeBagCallback: (removedBagId: number) => void;
}

export function UserFile({
  userProductId,
  userProductSecret,
  file,
  removeBagCallback,
}: UserFileProps) {
  const [bagId, setBagId] = useState("");

  let formatIcon = "📄";
  if (media.includes(file.Format)) formatIcon = "🖼";
  else if (archives.includes(file.Format)) formatIcon = "🗄";
  else if (text.includes(file.Format)) formatIcon = "📝";
  else if (directory.includes(file.Format)) formatIcon = "📂";

  const removeBagById = async () => {
    try {
      const response = await axios.post(
        `${TonProofMSApi.host}/storage/removeBag/${file.ID.toString()}`,
        {
          user_product_id: userProductId,
          secret: userProductSecret,
        }
      );

      if (response.status === 200) {
        console.info(file.BagID, "removed");
        removeBagCallback(file.ID);
      }
    } catch (e: unknown) {
      console.warn("remove bag error:", e);
    }
  };

  const loadInfoHandler = () => {
    setBagId(file.BagID);
  };

  const closeInfoHandler = () => {
    setBagId("");
  };

  return (
    <>
      <FileInfoModal
        bagId={bagId}
        onClose={closeInfoHandler}
        isOpened={!!bagId}
      />
      <tr>
        <td
          style={{
            fontWeight: file.New ? "bold" : "normal",
          }}>
          {formatIcon} {file.Format}&emsp;&emsp;&emsp;&nbsp;
        </td>
        <td>
          <div className="flex">
            <S.Link
              href={`${TonProofMSApi.host}/gateway/${file.BagID}`}
              target="_blank"
              rel="noreferrer"
              style={{
                fontWeight: file.New ? "bold" : "normal",
              }}
            >
              {file.BagID}
            </S.Link>
            <button
              type="button"
              title="copy Bag ID"
              onClick={() => {
                navigator.clipboard.writeText(file.BagID);
              }}
            >
              &nbsp;📋&nbsp;
            </button>
          </div>
        </td>
        <td 
          className="text-right"
          style={{
            fontWeight: file.New ? "bold" : "normal",
          }}>
          {bytesToSize(file.Size)}&emsp;&emsp;&emsp;
        </td>
        <td className="text-right">
          <S.Actions>
            <button
              type="button"
              className="h-[30px] w-[30px] border-2 rounded"
              title="info"
              onClick={loadInfoHandler}
            >
              &nbsp;i&nbsp;
            </button>
            <button
              type="button"
              className="h-[30px] w-[30px] border-2 rounded"
              title="remove Bag"
              onClick={removeBagById}
            >
              &nbsp;x&nbsp;
            </button>
          </S.Actions>
        </td>
      </tr>
    </>
  );
}
