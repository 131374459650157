import { useState } from "react";
import { IDownloadingCollectionRequestInfo, IWalletTransaction, NftDownloadInfo } from "../../../models";
import { lineWithLink, sendTransactionToWallet, tonStorageLink, tonscanLink } from "../../../utils";
import axios from "axios";
import { TonProofMSApi } from "../../../TonProofMSApi";
import { RevertCollectionData } from "./RevertCollectionData";
import { Modal } from "../../Modal";
import { useTranslation } from "react-i18next";

const MaxLen = 48;

export interface updateRequestItemProps {
    requestInfo: IDownloadingCollectionRequestInfo | null;
    onCanceled: (requestID: number) => void;
    onApproved: (requestID: number) => void;
}

export function UpdateRequestItem({ requestInfo, onCanceled, onApproved }: updateRequestItemProps) {
    const { t } = useTranslation("tools");  
    
    const [isDetailsVisible, setIsDetailsVisible] = useState(false);
    const [isBuildingMessage, setIsBuildingMessage] = useState(false);
    const [confirmViaWallet, setConfirmViaWallet] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);  
    const [error, setError] = useState("");
    const [revertModalVisible, setRevertModalVisible] = useState(false);
    const [isRevertSuccess, setIsRevertSuccess] = useState(false);
    const isLocal = window.location.href.indexOf("localhost") !== -1;

    const handleShowDetails = () => {
        setIsDetailsVisible(!isDetailsVisible);
    }

    const handleRevertClosed = () => {
        setRevertModalVisible(false);

        if (isRevertSuccess) 
            handleCancelClick();
    }

    function sendApproved() {
        if (!requestInfo) return;
        
        var config = {
            headers: {
                "Content-Type": "application/json",
            }
        }

        var data = {
            user_id: parseInt(TonProofMSApi.userID ? TonProofMSApi.userID : "0", 10),
            auth_token: TonProofMSApi.accessToken,
        }

        axios.post(`${TonProofMSApi.host}/dapp/tools/updateCollectionMetaApprove/${requestInfo?.id}`, data, config).then((response) => {
            console.log("approve response:", response.data);
            if (!response.data.message) {
                setError("not found");
                return;
            }

            onApproved(requestInfo.id);
        }).catch((error) => {
            console.log(error);
            if (error.response.data) {
                setError("when sending approve: " + error.response.data.message);
            } else {
                setError("when sending approve: " + error.message);
            }
        });
    }

    function sendTransaction(txInfo: IWalletTransaction) {
      const onCompleted = (boc: string) => {
        setConfirmViaWallet(false);
        setIsCompleted(true);
        sendApproved();
      };
  
      const onError = (error: string) => {
        setConfirmViaWallet(false);
        setError(error);
      };
      
      setConfirmViaWallet(true);
      sendTransactionToWallet([txInfo], onCompleted, onError);
    }

    const handleCancelClick = () => {
        if (!requestInfo) return;
    
        setError("");

        var config = {
            headers: {
            "Content-Type": "application/json",
            }
        }
    
        var data = {
            user_id: parseInt(TonProofMSApi.userID ? TonProofMSApi.userID : "0", 10),
            auth_token: TonProofMSApi.accessToken,
        }

        axios.post(`${TonProofMSApi.host}/dapp/tools/downloadCollectionRequestCancel/${requestInfo.id}`, data, config).then((response) => {
            console.log("cancel response:", response.data);
            if (!response.data.message) {
                setError("not found");
                return;
            }
    
            onCanceled(requestInfo.id);
        }).catch((error) => {
            setIsBuildingMessage(false);
            console.log(error);
            if (error.response.data) {
                setError(error.response.data.message);
            } else {
                setError(error.message);
            }
        });
    }

    const handleConfirmUpdateClick = () => {
      if (!requestInfo) return;
      if (!requestInfo.additional_data.info) return;
  
      var data = {
          collection_meta_json: "tonstorage://"+requestInfo.additional_data.info.collection_content_uri+"/collection.json",
          collection_common_uri: "tonstorage://"+requestInfo.additional_data.info.common_content_uri,
          royalty_address: requestInfo.additional_data.info.royalty_address,
          royalty_factor: requestInfo.additional_data.info.royalty_factor,
          royalty_base: requestInfo.additional_data.info.royalty_base,
        }
        
      setError("");
      setIsBuildingMessage(true);
      axios.post(`${TonProofMSApi.host}/dapp/tools/updateCollectionMeta/${requestInfo.collection_address}`, data).then((response) => {
        console.log("nft info response:", response.data);
        setIsBuildingMessage(false);
        if (!response.data.result) {
          setError("not found");
          return;
        }
  
        var transactionInfo = response.data.result as IWalletTransaction;
        sendTransaction(transactionInfo);
      }).catch((error) => {
        setIsBuildingMessage(false);
        console.log(error);
        if (error.response.data) {
          setError(error.response.data.message);
        } else {
          setError(error.message);
        }
      });
    }

    if (requestInfo == null) return (<></>);

    return (
        <>
            { revertModalVisible &&
                <Modal
                    title={t("TransferToStorage.RevertData")}
                    onClose={ handleRevertClosed } 
                    isOpened={ revertModalVisible }>
                    <RevertCollectionData 
                        requestInfo={requestInfo}
                        handleSuccessRevert={ () => { setIsRevertSuccess(true) } }/>
                </Modal>
            }
            { error && 
                <p style={{ textAlign: "center", color: "red" }}>{error}</p>
            }
            {/* Progress bar */}
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "40px",
                    border: "1px solid #e0e0e0",
                    marginTop: "5px",
                }}>

                {/* Progress */}
                <div 
                    style={{
                        width: requestInfo.additional_data.ready_to_update || requestInfo.additional_data.error ? "100%" 
                            : `${requestInfo.additional_data.progress}%`,
                        height: "100%",
                        top: 0,
                        left: 0,
                        backgroundColor: requestInfo.additional_data.error ? "#FFCCCB" : "lightgreen",
                        position: "absolute",
                        opacity: 0.3,
                    }}>&nbsp;</div>
                
                {/* Text */}
                <div 
                    style={{
                        width: `100%`,
                        height: "100%",
                        top: 0,
                        left: 0,
                        position: "absolute",
                    }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            height: "40px",
                        }}>

                        <p style={{ margin: 10 }}>{`#${requestInfo.id} ${requestInfo.additional_data.error ? "Error" 
                            : requestInfo.additional_data.status} ${requestInfo.additional_data.extra_info}`}</p>
                        <div 
                            style={{
                                display: "flex",
                            }}>
                            
                            <p style={{ margin: 10 }}>{ t("TransferToStorage.UsedSpace") }: { (requestInfo.additional_data.used_space / 1024 / 1024).toFixed(2) } Mb</p>

                            {/* Revert button */}
                            {
                                requestInfo.is_approved &&
                                <div
                                    style={{
                                        height: "30px",
                                        padding: "5px",
                                        margin: "4px",
                                        border: "1px solid black",
                                        cursor: "pointer",
                                        backgroundColor: "lightblue",
                                    }}
                                    onClick={ () => { setRevertModalVisible(true) } }>
                                    <p style={{ textAlign: "center", }}>{ t("TransferToStorage.RevertButton") }</p>
                                </div>
                            }

                            {/* Details button */}
                            <div
                                style={{
                                    height: "30px",
                                    padding: "5px",
                                    margin: "4px",
                                    border: "1px solid black",
                                    cursor: "pointer",
                                    backgroundColor: requestInfo.is_approved ? "lightblue" : 
                                        requestInfo.additional_data.ready_to_update ? "lightgreen" : "lightblue",
                                }}
                                onClick={ handleShowDetails }>
                                <p style={{ textAlign: "center", }}>{ requestInfo.is_approved ? t("TransferToStorage.Details") 
                                    : requestInfo.additional_data.ready_to_update ? t("TransferToStorage.Update") : t("TransferToStorage.Details") }</p>
                            </div>

                            {/* Cancel button */}
                            <div
                                style={{
                                    width: "30px",
                                    height: "30px",
                                    padding: "5px",
                                    margin: "4px",
                                    border: "1px solid black",
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                }}
                                onClick={ () => { handleCancelClick() } }>
                                <p style={{ textAlign: "center", }}>X</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Details */}
            { requestInfo.additional_data.info &&
                <div
                    style={{
                        display: isDetailsVisible ? "block" : "none",
                        border: "1px solid #e0e0e0",
                        marginTop: -1,
                        padding: "20px",
                    }}>

                    { requestInfo.additional_data.error &&
                        <p style={{ margin: 10, color: "red" }}>{ requestInfo.additional_data.error }</p>
                    }

                    <p><b>{ t("TransferToStorage.Details") }:</b></p>
                    <p>{ t("TransferToStorage.Product") }: <i>#{requestInfo.additional_data.product.id} {requestInfo.additional_data.product.title}</i></p>
                    <p>{ t("TransferToStorage.Testnet") }: <i>{requestInfo.is_testnet ? "true" : "false"}</i></p>
                    <p>{lineWithLink(t("CollectionAddress"), requestInfo.collection_address, tonscanLink(requestInfo.collection_address, requestInfo.is_testnet), MaxLen)}</p>
                   {
                        requestInfo.additional_data.ready_to_update && requestInfo.additional_data.info &&
                        <>
                            <br />
                            <hr />
                            <br />
                            <p><b>{ t("TransferToStorage.UpdateInfo") }:</b></p>
                            <p
                            style={{
                                marginTop: "10px",
                            }}><span
                            style={{
                                textAlign: "center",
                                color: "blue",
                            }}
                            >ℹ️</span> { t("TransferToStorage.UpdateRequestHint1") }.</p>
                            <p
                            style={{
                                marginTop: "10px",
                            }}><span
                            style={{
                                textAlign: "center",
                                color: "red",
                            }}
                            >⚠️</span> { t("TransferToStorage.UpdateRequestHint2") }: 
                                <a 
                                href="https://t.me/tonbytesupport" 
                                target="_blank"
                                style={{
                                    color: "blue",
                                }}> @tonbytesupport</a>.</p>
                            <br />
                            <br />

                            <p>{ t("TransferToStorage.CheckOldAndNewData") }.</p>
                            <br />
                            <p>{lineWithLink(t("TransferToStorage.OldCURI"), requestInfo.additional_data.info.old_collection_content_uri, tonStorageLink(requestInfo.additional_data.info.old_collection_content_uri), MaxLen)}</p>

                            <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between"}}>
                                {lineWithLink(t("TransferToStorage.WillBeReplaced"), "tonstorage://"+requestInfo.additional_data.info.collection_content_uri+"/collection.json", tonStorageLink("tonstorage://"+requestInfo.additional_data.info.collection_content_uri, isLocal), MaxLen)}
                                {/* <p>[✏️ edit]</p> */}
                            </div>
                            <br />
                            <p>{lineWithLink(t("TransferToStorage.OldCCURI"), requestInfo.additional_data.info.old_common_content_uri, tonStorageLink(requestInfo.additional_data.info.old_common_content_uri), MaxLen)}</p>
                            <div 
                            style={{
                                display: "flex",
                                justifyContent: "space-between"}}>
                                {lineWithLink(t("TransferToStorage.WillBeReplaced"), "tonstorage://"+requestInfo.additional_data.info.common_content_uri, tonStorageLink("tonstorage://"+requestInfo.additional_data.info.common_content_uri, isLocal), MaxLen)}
                                {/* <p>[✏️ edit]</p> */}
                            </div>
                            <br />
                            {lineWithLink(t("TransferToStorage.NewCollectionData"), "tonstorage://"+requestInfo.additional_data.info.nft_offchain_content_uri, tonStorageLink("tonstorage://"+requestInfo.additional_data.info.nft_offchain_content_uri, isLocal), MaxLen)}
                        </>
                    }

                    {/* Confirm transaction */}
                    { requestInfo.additional_data.ready_to_update && !requestInfo.is_approved && (
                        <div 
                        style={{
                            margin: "35px 35px 0 35px",
                        }}>
                        { !isCompleted && <div 
                            style={{
                                border: "2px solid #e5e7eb",
                                width: "30%",
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: "30px",
                                padding: "10px",
                                cursor: "pointer",
                                backgroundColor: confirmViaWallet ? "white" : "lightblue",
                            }}
                            onClick={handleConfirmUpdateClick}
                            >
                                <p style={{ textAlign: "center" }}>{ confirmViaWallet ? t("CheckWallet") 
                                                                                    : (isBuildingMessage ? t("BuildingMessage") 
                                                                                                        : t("SendUpdate")) }</p>
                            </div>
                        }

                        { isCompleted && <div>
                            <br />
                            <p style={{ fontSize: 40, textAlign: "center" }}>🎉</p>
                            <p style={{ textAlign: "center" }}>{ t("Done") }</p>
                            <p style={{ textAlign: "center" }}>{ t("TransferToStorage.NowInStorage") }!</p>
                            </div>
                        }
                        </div>
                    )}

                    { requestInfo.is_approved && 
                        <div style={{ marginTop: "30px" }}>
                            <p style={{ textAlign: "center" }}>{ t("TransferToStorage.NowInStorage") }.</p>
                        </div>
                    }
                </div>
            }
        </>
    );
}
