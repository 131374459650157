import axios from "axios";
import { useEffect, useState } from "react";

import { BagShortInfo } from "../models";
import { TonProofMSApi } from "../TonProofMSApi";

const lastId = 0;
const limit = 100;

export function useFilesSimplePagination(userProductId: number, accessKey: string) {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<BagShortInfo[]>([]);

  // if (files.length > 0)
  // lastId = files[files.length-1].ID

  async function fetchFiles() {
    try {
      setLoading(true);

      const response = await axios.post(`${TonProofMSApi.host}/storage/productBags`, {
        user_product_id: userProductId,
        access_key: accessKey,
        last_id: lastId,
        limit,
      });

      setLoading(false);

      const finalFiles = response.data.bags;
      if (!finalFiles) {
        console.info("no user files");
        return;
      }

      Array.prototype.push.apply(finalFiles, files);
      setFiles(finalFiles);

      console.info("got user files", response.data);
    } catch (e: unknown) {
      console.warn("fetching user files error:", e);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchFiles();
  }, [lastId]);

  return { loading, files, setFiles };
}
