import { styled } from "../../shared/lib/styles";

export const Burger = styled("span", {
  display: "flex",
  alignItems: "center",
  position: "relative",
  backgroundColor: "inherit",
  transition: "0.2s ease-in-out",
  border: "none",
  outline: "none",
  width: "20px",
  height: "20px",
  cursor: "pointer",
  zIndex: "100",

  "&::before": {
    content: "",
    position: "absolute",
    width: "100%",
    height: "2px",
    top: "0",
    left: "0",
    borderRadius: "9999px",
    transform: "rotate(0deg)",
    transition: "0.2s ease-in-out",
    background: "#000",
  },

  "&::after": {
    content: "",
    position: "absolute",
    width: "100%",
    height: "2px",
    bottom: "0",
    left: "0",
    background: "#000",
    borderRadius: "9999px",
    transform: "rotate(0deg)",
    transition: "0.2s ease-in-out",
  },

  variants: {
    isActive: {
      true: {
        zIndex: "-100",
        display: "none",
        "&::before": {
          zIndex: "-100",
          width: "0",
          height: "0",
          top: "45%",
          display: "none",
        },
        "&::after": {
          zIndex: "-100",
          width: "0",
          height: "0",
          top: "45%",
          display: "none",
        },
      },
    },
  },
});

export const MiddleLine = styled("span", {
  display: "block",
  width: "100%",
  height: "2px",
  background: "#000",
  borderRadius: "9999px",
  transition: "0.2s ease-in-out",

  variants: {
    isActive: {
      true: {
        display: "none",
      },
    },
  },
});
