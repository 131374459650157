import { codepen, CopyBlock } from "react-code-blocks";
import { useTranslation } from "react-i18next";

import { useBagInfo } from "../../hooks/useBagInfo";
import { Modal } from "../Modal";
import * as S from "../../app/style";

interface FileInfoModalProps {
  bagId: string;
  onClose: () => void;
  isOpened: boolean;
}

export function FileInfoModal({ bagId, onClose, isOpened }: FileInfoModalProps) {
  const { loading, bagInfo, peers } = useBagInfo(bagId);
  const { t } = useTranslation("fileInfoModal");

  return (
    <Modal title={`BagID: ${bagId}`} onClose={onClose} isOpened={isOpened}>
      {loading && <p className="text-center">{t("loading")}</p>}
      <div>
          {!loading && bagInfo && (
            <div className="py-2 px-4">
              <p>Info:</p>
              <S.Scrollable style={{ height: "250px" }}>
                <CopyBlock
                  language="json"
                  text={JSON.stringify(bagInfo, null, 2)}
                  showLineNumbers={false}
                  theme={codepen}
                  wrapLines
                  codeBlock
                  wrapLongLines={true}
                />
              </S.Scrollable>
            </div>
          )}
          <br />
          {!loading && peers && (
            <div className="py-2 px-4">
              <p>Peers:</p>
              <S.Scrollable style={{ height: "250px" }}>
                <CopyBlock
                  language="json"
                  text={JSON.stringify(peers, null, 2)}
                  showLineNumbers={false}
                  theme={codepen}
                  wrapLines
                  codeBlock
                  wrapLongLines={true}
                />
              </S.Scrollable>
            </div>
          )}
      </div>
    </Modal>
  );
}
