import axios, { AxiosError } from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { NftItem } from "../models";

interface UserDomainsProps {
  userAddress: string;
  setError: Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export function useUserDomains({ userAddress, setError, setLoading }: UserDomainsProps) {
  const [domains, setDomains] = useState<NftItem[] | null>(null);

  async function fetchUserDomainsList() {
    console.info("trying to load wallet domains...");
    if (userAddress.length === 0) {
      setError("can not get user wallet address. Seems you are not logged in.");
      return;
    }

    setError("");
    setDomains(null);
    setLoading(true);

    axios({
      method: "get",
      url: `https://tonapi.io/v1/nft/searchItems?owner=${userAddress}&collection=0:b774d95eb20543f186c06b371ab88ad704f7e256130caf96189368a7d0cb6ccf&include_on_sale=false&limit=30&offset=0`,
      headers: {
        accept: "application/json",
      },
    }).then((response) => {
        setLoading(false);
        console.info("user domains", response);

        if (!response.data.nft_items) throw "wrong response";

        setDomains(response.data.nft_items);
    }).catch((e) => {
      setLoading(false);
      setDomains(null);
      console.warn(`can not get user domains: ${e}`);

      const err = e as AxiosError;
      if (err) {
        setError(err.message);
      } else {
        setError("can not get wallet .ton domains");
      }
    });
  }

  useEffect(() => {
    fetchUserDomainsList();
  }, [userAddress]);

  return domains;
}
