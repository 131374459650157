import { styled } from "@stitches/react";

export const ModalWallet = styled("div", {
  position: "fixed",
  inset: 0,
  zIndex: 100,

  visibility: "hidden",
  overflow: "auto",
  backgroundColor: "#fff",
  transition: "0.2s ease-in-out",
  opacity: "0",
  display: "flex",
  alignItems: "center",

  variants: {
    isOpened: {
      true: {
        visibility: "visible",
        opacity: "1",
        overflow: "hidden",
      },
    },
  },
});

export const WrapperModal = styled("div", {
  width: "80%",
  margin: "0 auto",
});

export const WrapperInnerModal = styled("div", {});

export const TitleModal = styled("h2", {
  fontWeight: "700",
  fontSize: "30px",
  lineHeight: "36px",
  textAlign: "center",

  "@media(max-width: 620px)": {
    fontSize: "25px",
    lineHeight: "30px",
  },
});

export const ButtonWrapper = styled("div", {
  position: "absolute",
  zIndex: 105,
  right: "100px",
  top: "100px",
  cursor: "pointer",

  "@media(max-width: 1024px)": {
    right: "50px",
    top: "50px",
  },

  "@media(max-width: 630px)": {
    right: "25px",
    top: "25px",
  },
});

export const TextModal = styled("p", {
  textAlign: "center",
  fontWeight: "600",
  fontSize: "18px",
  lineHeight: "36px",

  "@media(max-width: 620px)": {
    fontSize: "18px",
    lineHeight: "20px",
    margin: "15px 0",
  },
});

export const ListWallets = styled("ul", {
  maxWidth: "525px",
  display: "flex",
  justifyContent: "space-around",
  flexWrap: "wrap",
  margin: "15px auto",
});

export const Wallet = styled("li", {
  width: "131px",
  "@media(max-width: 400px)": {
    margin: "20px 0",
  },
});

export const Link = styled("button", {
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
});

export const Image = styled("img", {
  width: "50px",
  height: "50px",
  margin: "0 auto",
  display: "block",
  "&:first-child": {
    borderRadius: "50%",
  },
});

export const NameWallet = styled("h2", {
  fontWeight: "600",
  fontSize: "18px",
  lineHeight: "36px",
  marginTop: "8px",
  textAlign: "center",
});

export const WrapperQRModal = styled("div", {
  position: "relative",
  top: "100%",
});

export const WrapperCloseBtn = styled("div", {
  position: "absolute",
  top: "0",
  right: "-50px",
});
