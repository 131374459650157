import { styled } from "../../shared/lib/styles";

export const ContainerTitle = styled("div", {});

export const BrTitle = styled("br", {
  "@tablet": {
    display: "none",
  },
});

export const TitleMain = styled("h1", {
  "@laptop": {
    fontSize: "22px",
    lineHeight: "30px",
  },
  "@tablet": {
    fontSize: "20px",
  },
  "@wideMobile": {
    fontSize: "18px",
  },
  "@mobile": {
    fontSize: "15px",
  },
});

export const Subtitle = styled("p", {
  "@laptop": {
    fontSize: "22px",
    lineHeight: "30px",
  },
  "@tablet": {
    fontSize: "20px",
    marginBottom: "10px",
    lineHeight: "26px",
  },
  "@wideMobile": {
    fontSize: "18px",
    lineHeight: "20px",
  },
  "@mobile": {
    fontSize: "14px",
  },
});

export const Hr = styled("hr", {
  "@laptop": {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
});

export const FeaturesContainer = styled("div", {});

export const Features = styled("h2", {});

export const FileStorage = styled("h3", {
  margin: "7px 0",

  "@tablet": {
    margin: "10px 0",
  },
});

export const FileStorageText = styled("p", {
  "@tablet": {
    marginBottom: "10px",
  },
  "@mobile": {
    marginBottom: "17px",
  },
});

export const FileStorageLink = styled("a", {});

export const GateWay = styled("h3", {
  margin: "7px 0",

  "@tablet": {
    margin: "10px 0",
  },
});

export const GateWayText = styled("p", {
  "@tablet": {
    marginBottom: "10px",
  },
  "@media(max-width: 461px)": {
    marginBottom: "17px",
  },
});

export const GateWayLink = styled("a", {});

export const TonSites = styled("h3", {
  margin: "7px 0",

  "@tablet": {
    margin: "10px 0",
  },
});

export const TonSitesText = styled("p", {
  "@tablet": {
    marginBottom: "10px",
  },
  "@media(max-width: 461px)": {
    marginBottom: "17px",
  },
});

export const TonSitesLink = styled("a", {});

export const TrueDecentralized = styled("h3", {
  margin: "7px 0",

  "@tablet": {
    margin: "10px 0",
  },
});

export const TrueDecentralizedText = styled("p", {
  "@tablet": {
    marginBottom: "10px",
  },
  "@media(max-width: 461px)": {
    marginBottom: "17px",
  },
});

export const TrueDecentralizedLink = styled("a", {});

export const PrivateStorage = styled("h3", {
  margin: "7px 0",

  "@tablet": {
    margin: "10px 0",
  },
});

export const PrivateStorageText = styled("p", {
  "@tablet": {
    marginBottom: "10px",
  },
  "@media(max-width: 461px)": {
    marginBottom: "17px",
  },
});

export const PricesContainer = styled("div", {});

export const PricesTitle = styled("h2", {});

export const ListProduct = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",

  "@wideTablet": {
    gridTemplateColumns: "repeat(3, 1fr)",
  },

  "@tablet": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  "@wideMobile": {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
});

export const LoadingProducts = styled("p", {
  fontSize: "20px",
});
