import { Option } from "../../../components/Select/types";

export const LANGUAGES: Option[] = [
  {
    value: "en",
    label: "EN",
  },
  {
    value: "ru",
    label: "RU",
  },
];
