import { useTranslation } from "react-i18next";
import axios from "axios";

import { useEffect, useState } from "react";
import { IDownloadingCollectionRequestInfo } from "../../../models";
import { TonProofMSApi } from "../../../TonProofMSApi";
import { UpdateRequestItem } from "./UpdateRequestItem";
import { NewDownloadCollectionRequest } from "./NewDownloadCollectionRequest";
import { useTonWallet } from "../../../hooks/useTonWallet";
import { useUserProducts } from "../../../hooks/useUserProducts";
import { wait } from "@testing-library/user-event/dist/utils/misc/wait";

interface NftCollectionUpdateToolProps {
}

export function NftCollectionUpdateTool({}: NftCollectionUpdateToolProps) {
  const { t } = useTranslation("tools");  
  
  const { userProducts, loading } = useUserProducts();
  const wallet = useTonWallet();
  
  const [error, setError] = useState("");
  const [newNftDownloadRequest, setNewNftDownloadRequest] = useState<IDownloadingCollectionRequestInfo | null>(null);
  const [inProgressNftDownloadRequests, setInProgressNftDownloadRequests] = useState<IDownloadingCollectionRequestInfo[] | null>(null);
  const [approvedNftDownloadRequests, setApprovedNftDownloadRequests] = useState<IDownloadingCollectionRequestInfo[] | null>(null);
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [requestID, setRequestID] = useState<number | null>(null);

  const onGotError = (error: string) => {
    setError(error);
  }

  const onApproveSent = (requestID: number) => {
    console.info("Approve sent", requestID)
    if (requestID == newNftDownloadRequest?.id) {
      var downloadRequest = newNftDownloadRequest;
      downloadRequest.is_approved = true;
      setNewNftDownloadRequest(downloadRequest);
    } else if (inProgressNftDownloadRequests?.find((requestInfo) => requestInfo.id == requestID) !== undefined) {
      var downloadRequests = inProgressNftDownloadRequests;
      downloadRequests.map((requestInfo) => {
        if (requestInfo.id == requestID) {
          requestInfo.is_approved = true;
        }
      });

      setInProgressNftDownloadRequests(downloadRequests);
    }
  }

  const onRequestCanceled = (requestID: number) => {
    console.info("Request canceled", requestID)
    
    if (requestID == newNftDownloadRequest?.id) {
      setNewNftDownloadRequest(null);
      updateInProgressRequests();
    } else if (inProgressNftDownloadRequests?.find((requestInfo) => requestInfo.id == requestID) !== undefined) {
      var result = inProgressNftDownloadRequests.filter((requestInfo) => requestInfo.id != requestID)
      setInProgressNftDownloadRequests(result.length > 0 ? result : null);
    }
  }

  const updateInProgressRequests = () => {
    if (!TonProofMSApi.accessToken) return;

    var config = {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        approved: false,
      },
    }

    var data = {
      user_id: parseInt(TonProofMSApi.userID ? TonProofMSApi.userID : "0", 10),
      auth_token: TonProofMSApi.accessToken,
    }

    axios.post(`${TonProofMSApi.host}/dapp/tools/downloadCollectionRequests/0`, data, config).then((response) => {
      console.log("download collection requests response:", response.data);
      if (!response.data.result) {
        return;
      }

      var result = response.data.result as IDownloadingCollectionRequestInfo[];
      if (result.length > 0) {
        setInProgressNftDownloadRequests(result);
      }
    }).catch((error) => {
      console.log(error);
      if (error.response.data) {
        setError(error.response.data.message);
      } else {
        setError(error.message);
      }
    });
  }
  
  const startUpdateInProgressRequests = (requests: IDownloadingCollectionRequestInfo[] | null) => {
    const intervalInProgressId = setInterval(() => {
      console.info("updateInProgressRequests")
      if (!requests || requests.length == 0) {
        clearInterval(intervalInProgressId);
        return;
      }

      if (requests.filter((requestInfo) => !requestInfo.additional_data.ready_to_update).length == 0) {
        clearInterval(intervalInProgressId);
        return;
      }

      updateInProgressRequests();
    }, 8000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalInProgressId);
    };
  }

  useEffect(() => {
    updateInProgressRequests();
    wait(10000).then(() => {
      startUpdateInProgressRequests(inProgressNftDownloadRequests);
    });
  }, []);

  useEffect(() => {
    if (!TonProofMSApi.accessToken) return;

    var config = {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        approved: true,
      },
    }

    var data = {
      user_id: parseInt(TonProofMSApi.userID ? TonProofMSApi.userID : "0", 10),
      auth_token: TonProofMSApi.accessToken,
    }

    axios.post(`${TonProofMSApi.host}/dapp/tools/downloadCollectionRequests/1`, data, config).then((response) => {
      console.log("download collection requests response:", response.data);
      if (!response.data.result) {
        return;
      }

      var result = response.data.result as IDownloadingCollectionRequestInfo[];
      if (result.length > 0) {
        setApprovedNftDownloadRequests(result);
      }
    }).catch((error) => {
      console.log(error);
      if (error.response.data) {
        setError(error.response.data.message);
      } else {
        setError(error.message);
      }
    });
  }, []);

  useEffect(() => {
    if (!requestID) return;
    
    const intervalNewRequestId = setInterval(() => {
      var config = {
        headers: {
          "Content-Type": "application/json",
        }
      }

      var data = {
        user_id: parseInt(TonProofMSApi.userID ? TonProofMSApi.userID : "0", 10),
        auth_token: TonProofMSApi.accessToken,
      }

      axios.post(`${TonProofMSApi.host}/dapp/tools/downloadCollectionRequest/${requestID}`, data, config).then((response) => {
        console.log("download collection request response:", response.data);
        if (!response.data.result) {
          setError("not found");
          return;
        }

        var result = response.data.result as IDownloadingCollectionRequestInfo;
        if (result) {

          setNewNftDownloadRequest(result);

          if (result.additional_data.ready_to_update || result.state === 3 || result.state === 2) {
            clearInterval(intervalNewRequestId);
            setRequestID(null);
          }
        }
      }).catch((error) => {
        console.log(error);
        if (error.response.data) {
          setError(error.response.data.message);
        } else {
          setError(error.message);
        }
      });
    }, 2000);

    return () => {
      // Clean up the interval on component unmount
      clearInterval(intervalNewRequestId);
    };
  }, [requestID]);

  const onGotDownloadRequestID = (requestID_: number) => {
    setRequestID(requestID_);
  }

  if (!wallet) {
    return <p style={{ color: "red", textAlign: "center" }}>&nbsp;{ t("AuthenticationRequired") }</p>
  }
  
  return (
    <div
      style={{
        margin: "25px"
      }}>

      { error &&
        <p style={{ color: "red", textAlign: "center" }}>&nbsp;{ error ? error : "" }</p>
      }

      {!loading && userProducts.length === 0 && (
          <p className="text-center">{ t("TransferToStorage.NoProducts") }</p>
      )}

      { approvedNftDownloadRequests && approvedNftDownloadRequests.length > 0 &&
          <>
            <p 
              style={{ 
                textAlign: "right", 
                color: "blue",
                cursor: "pointer", }}
              onClick={() => { setIsHistoryVisible(!isHistoryVisible) }}>[{isHistoryVisible ? t("TransferToStorage.HideHistory") : t("TransferToStorage.ShowHistory")}]</p>
            { isHistoryVisible && approvedNftDownloadRequests.length > 0 && 
              <>
                { approvedNftDownloadRequests.map((requestInfo, index) => {
                  return (
                    <UpdateRequestItem
                      key={index}
                      requestInfo={requestInfo}
                      onCanceled={onRequestCanceled}
                      onApproved={onApproveSent}
                      />
                  );
                })}
                <br />
                <hr />
                <br />
              </>
            }
            <br />
          </>
      }

      {loading && (
          <p className="text-xl text-center">...</p>
      )}

      { !newNftDownloadRequest && inProgressNftDownloadRequests && inProgressNftDownloadRequests.length > 0 &&
          <>
            <p>{ t("TransferToStorage.InProgress") }:</p>
            { inProgressNftDownloadRequests.map((requestInfo, index) => {
              return (
                <UpdateRequestItem
                  key={index}
                  requestInfo={requestInfo}
                  onCanceled={onRequestCanceled}
                  onApproved={onApproveSent}
                  />
              );
            }) 
            }
          </>
      }

      { 
        newNftDownloadRequest &&
          <>
            <p>{ t("TransferToStorage.NewRequest") }:</p>
            <UpdateRequestItem
              requestInfo={newNftDownloadRequest}
              onCanceled={onRequestCanceled}
              onApproved={onApproveSent}
              />
          </>
      }

      { wallet && userProducts.length > 0 && !newNftDownloadRequest && !inProgressNftDownloadRequests &&
        <NewDownloadCollectionRequest
          onGotDownloadRequestID={onGotDownloadRequestID}
          onGotError={onGotError}
          userProducts={userProducts}
        />
      }
 
    </div>
  );
}
