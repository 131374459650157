import { useTranslation } from "react-i18next";
import { priceTextInTon } from "../../utils";
import * as S from "./style";

export interface PeriodItemProps {
    toncoinPrice: number;
    oneMonthPrice: number;
    months: number;
    discount: number;
    selected: boolean;
    onSelected: () => void;
}

export function PeriodItem({ toncoinPrice, oneMonthPrice, months, discount, selected, onSelected }: PeriodItemProps ) {
    const { t } = useTranslation("configureProduct");
    const totalPriceInCent = oneMonthPrice * months
    const discountPriceInCent = totalPriceInCent * discount / 100;
    const finalPrice = totalPriceInCent - discountPriceInCent;

    return (
        <S.PeriodItem style={{
            borderColor: selected ? 'black' : '#E5E5E5',
            backgroundColor: selected ? '#F5FCFF' : 'white',
        }}
        onClick={onSelected}>
            {/* TODO: use i18 plurals */}
            <S.TitleH2 className="text-center">{months} {months > 1 ? (months < 5 ? t("month2") : t("month5")) : t("month1")}</S.TitleH2>
            {
                discountPriceInCent == 0 &&
                <>
                    <p className="text-center">{t("nodiscount")}</p>
                    <p className="text-center">${finalPrice / 100} {priceTextInTon(finalPrice, toncoinPrice)}</p>
                </>
            }
            {
                discountPriceInCent > 0 && 
                <>
                    <p className="text-center"><b>{t("discount1")} {discount}% {t("discount2")}</b></p>
                    <p className="text-center"><s>${totalPriceInCent / 100}</s></p>
                    <p className="text-center">${finalPrice / 100}
                    <span className="text-gray-600">
                        &nbsp;{priceTextInTon(finalPrice, toncoinPrice)}
                    </span> </p>
                </>
            }
        </S.PeriodItem>
    );
}
