import { styled } from "../../../../shared/lib/styles";
import { CloseButton } from "../../../CloseButton/style";

export const SidebarOverlay = styled("div", {
  position: "fixed",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",

  visibility: "hidden",
  overflow: "auto",
  backgroundColor: "rgba(0, 0, 0, 0.4)",
  transition: "0.2s ease-in-out",
  opacity: "0",

  variants: {
    isOpened: {
      true: {
        visibility: "visible",
        opacity: "1",
      },
    },
  },
});

export const SidebarInnerWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  zIndex: "100",
});

export const Sidebar = styled("div", {
  width: "70%",
  backgroundColor: "#fff",
  boxShadow: "0px 20px 10px 0px rgba(0, 0, 0, 0.5)",
  transform: "translateX(-100%)",
  transition: "0.2s ease-in-out",
  height: "100%",

  variants: {
    isActive: {
      true: {
        transform: "translateX(0)",
      },
    },
  },
});

export const UserActions = styled("ul", {
  padding: "15px 15px 0 15px",
  display: "flex",
  flexDirection: "column",
});

export const WrapperNavLink = styled("div", {
  width: "100%",
  padding: "15px",
  textAlign: "center",
  fontSize: "20px",

  "@wideMobile": {
    fontSize: "16px",
  },
});

export const ButtonConnectWrapper = styled("div", {
  margin: "15px auto",
  fontSize: "14px",
});

export const CloseButtonWrapper = styled(CloseButton, {
  position: "absolute !important",
  right: "20px",
  top: "20px",
  cursor: "pointer",
});

export const WrapperSelectLang = styled("div", {
  position: "absolute",

  "@wideMobile": {
    position: "relative",
    margin: "10px auto",
  },
});
