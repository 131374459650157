import axios from "axios";
import { useEffect, useState } from "react";

import { IBagFullInfo, IBagInfoResponse, IBagPeers } from "../models";
import { TonProofMSApi } from "../TonProofMSApi";

export function useBagInfo(bagId: string) {
  const [loading, setLoading] = useState(false);
  const [bagInfo, setBagInfo] = useState<IBagFullInfo | null>(null);
  const [peers, setPeers] = useState<IBagPeers | null>(null);

  async function fetchBagInfo() {
    if (!bagId) {
      setBagInfo(null);
      setPeers(null);

      return;
    }

    try {
      setLoading(true);
      const response = await axios.get(
        `${TonProofMSApi.host}/storage/bagInfo/${bagId}`
      );

      console.log(response.data.result);
      const bagInfoResponse = response.data.result as IBagInfoResponse;
      if (!bagInfoResponse) {
        console.warn("got empty bag info");
        setLoading(false);
        return;
      }

      setPeers(bagInfoResponse.peers);
      setBagInfo(bagInfoResponse.bag_info);
      setLoading(false);

      console.info("got bag info", response.data);
    } catch (e: unknown) {
      console.warn("fetching bag info error:", e);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchBagInfo();
  }, [bagId]);

  return { loading, bagInfo, peers};
}
