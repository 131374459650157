import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { connector } from "../../../../connector";
import { useLoggedIn } from "../../../../hooks/useLoggedIn";
import { LANGUAGES } from "../../../../shared/lib/constants";
import { ModalWallets } from "../../../ModalWallets";
import { MyButton } from "../../../MyButton";
import { Select } from "../../../Select";

export const NavigationDesktop: FC = () => {
  const loggedIn = useLoggedIn();
  const [isOpened, setIsOpened] = useState(false);
  const { t, i18n } = useTranslation(["common", "other"]);

  return (
    <div>
      <nav className="h-[50px] flex justify-between px-5 items-center text-black">
        <Link to="/" className="font-bold">
          💎 {t("TONByteLogo")}
        </Link>

        <div className="flex items-center">
          <Link to="/tools" className="mr-6">
            {t("tools")}
          </Link>
          <Link to="/docs" className="mr-6">
            {t("API")}
          </Link>
          {loggedIn && (
            <span>
              |&nbsp;&nbsp;
              <Link to="/account" className="mr-6">
                🧑‍💻 {t("ACCOUNT")}
              </Link>
            </span>
          )}
          {/* <AuthButton/> */}
          <div className="mr-6">
            <Select
              options={LANGUAGES}
              onChange={(value) => i18n.changeLanguage(value)}
              value={i18n.language || LANGUAGES[0].value}
              hideCurrentValue
            />
          </div>
          {!loggedIn && <MyButton clicked={() => setIsOpened(true)} text={t("connectWallet")} />}

          {loggedIn && (
            <MyButton
              clicked={() => {
                connector.disconnect();
              }}
              text={t("other:disconnect")}
            />
          )}
          <ModalWallets
            handleClose={() => {
              setIsOpened(false);
            }}
            isOpened={isOpened}
          />
        </div>
      </nav>
    </div>
  );
};
