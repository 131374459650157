import { styled } from "../../shared/lib/styles";

export const Overlay = styled("div", {
  display: "flex",
  alignItems: "center",

  zIndex: 500,

  cursor: "pointer",
});

export const WindowModal = styled("div", {
  zIndex: 501,
  width: "60%",
  cursor: "default",

  "@wideTablet": {
    width: "60%",
  },

  "@tablet": {
    width: "80%",
  },

  "@wideMobile": {
    width: "90%",
  },

});
