import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";

import { Navigation } from "../components/Navigation";
import { connector } from "../connector";
import { AccountPage, DocsPage, ProductPage, ToolsPage } from "../pages";
import { setupGlobalStyles } from "../shared/lib/styles";
import * as S from "./style";

export const App = () => {
  setupGlobalStyles();

  const { t } = useTranslation("common");

  useEffect(() => {
    connector.restoreConnection();
  }, []);

  return (
    <S.Container className="w-2/3 mx-auto">
      <Navigation />
      <Routes>
        <Route path="/" element={<ProductPage />} />
        <Route path="/docs" element={<DocsPage />} />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/tools" element={<ToolsPage />} />
      </Routes>
      <div className="p-4 rounded-lg md:px-6 md:py-8">
        <hr className="my-6 lg:my-8" />
        <a href="https://t.me/tonbytecom">{t("TelegramChannel")}</a>
        <p />
        <a href="https://t.me/tonbytesupport">{t("TelegramChat")}</a>
        <p />
        <a href="https://twitter.com/TON_Byte">{t("Twitter")}</a>
        <p />
        <a href="https://ton.app/utilities/tonbyte">{t("TONAppStore")}</a>
        <p className="text-right text-gray-600">{t("TONByte")}</p>
      </div>
    </S.Container>
  );
};
