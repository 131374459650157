import React, { FC } from "react";

import * as S from "./style";
import { BurgerProps } from "./types";

export const Burger: FC<BurgerProps> = ({ onCLick, isActive }) => (
  <S.Burger onClick={onCLick} isActive={isActive}>
    <S.MiddleLine isActive={isActive} />
  </S.Burger>
);
