
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { sendTransaction } from "../connector";
import { useTonWallet } from "../hooks/useTonWallet";
import { HardcodedWalletInfo, IInvoice } from "../models";
import { TonProofMSApi } from "../TonProofMSApi";
import { Modal } from "./Modal";

interface PaymentModalsProps {
  productID: number;
  selectedNodes: number[];
  selectedPeriod: number;
  onClose: () => void;
}

export function PaymentModals({
    productID,
    selectedNodes,
    selectedPeriod,
    onClose,
  }: PaymentModalsProps) {
    const [initPayment, setInitPayment] = useState<boolean>(true);
    const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
    const [waitingForPayment, setWaitingForPayment] = useState<boolean>(false);
    const [isNoWalletModal, setIsNoWalletModal] = useState<boolean>(false);
    const [isErrorModal, setIsErrorModal] = useState<boolean>(false);
    const [bocString, setBocString] = useState<string>("");
    const [invoiceID, setInvoiceID] = useState<string>("");
    const [paid, setPaid] = useState<boolean>(false);
    const [transactionError, setTransactionError] = useState<string>("");
    const wallet = useTonWallet();
    const { t } = useTranslation("pay");
  
    const closeModalHandler = () => {
      // TODO: is this need?

      setPaid(false);
      setWaitingForPayment(false);
      setShowPaymentModal(false);
      setTransactionError("");

      onClose();
    };
  
    const handleCloseNoWallet = () => {
      setIsNoWalletModal(false);
    };
  
    const handleCloseError = () => {
      setIsErrorModal(false);
    };
  
    const sendTransactionHandler = async () => {
      console.warn(wallet);
      if (!wallet) {
        setIsNoWalletModal(true);
        console.warn("connect wallet first!");
        return;
      }
  
      await TonProofMSApi.getBuyProductInvoice(productID, selectedNodes, selectedPeriod)
        .then((data) => {
          if (!data) {
            setIsErrorModal(true);
            console.error("invoice is null");
            return;
          }
  
          setWaitingForPayment(true);
          setShowPaymentModal(true);
          setInvoiceID(data.InvoiceID);
  
          console.log("send transaction");
          try {
            sendTransaction(
              {
                validUntil: Date.now() + 1000000,
                messages: [
                  {
                    address: data.StoreAddress,
                    payload: data.Cell,
                    amount: String(data.Amount),
                  },
                ],
              },
              HardcodedWalletInfo
            )
            .then((data) => {
              console.info(`transaction result: ${data}`);
              setBocString(data.boc);
            })
            .catch(() => {
              setTransactionError(t("rejectedTransaction") as string);
            });
          } catch (e) {
            setIsErrorModal(true);
            console.log("USER REJECTED");
            console.warn(e as string);
          }
        })
        .catch((res) => {
          console.log("USER REJECTED", res);
        });
    };
  
    useEffect(() => {
      if (!bocString) return;
      if (!invoiceID) return;
  
      console.info(`got boc: ${bocString}`);
      TonProofMSApi.checkBuyProduct(productID, invoiceID)
        .then((message) => {
          console.info("backend says:", message);
  
          if (message === "ok") {
            console.info("paid!");
            setWaitingForPayment(false);
            setPaid(true);
          } else {
            console.warn("payment check error");
            setTransactionError(message ? message : "payment error");
          }
        })
        .catch((err) => {
          console.log("USER REJECTED", err);
        });
    }, [bocString]);
  
    const waitingWidget = useMemo(
      () => (
        <div>
          {waitingForPayment && !transactionError && (
            <div>
              <br />
              <p className="text-xl text-center">⌛</p>
              <p className="text-center">{t("waitTransaction")}</p>
            </div>
          )}
          {paid && !transactionError && (
            <div>
              <br />
              <p className="text-xl text-center">🎉</p>
              <p className="text-center">{t("thanksForTransaction")}</p>
            </div>
          )}
          {transactionError && (
            <div>
              <br />
              <p className="text-xl text-center">❌</p>
              <p className="text-center">{transactionError}</p>
            </div>
          )}
        </div>
      ),
      [waitingForPayment, transactionError, paid]
    );
  
    useEffect(() => {
      if (initPayment) {
        setInitPayment(false);
        sendTransactionHandler();
      }
    }, []);

    return (
      <>
        <Modal
          title={t("CheckYourWallet")}
          onClose={closeModalHandler}
          isOpened={showPaymentModal}
        >
          {waitingWidget}
        </Modal>
        <Modal
          title={t("connectWallet")}
          onClose={handleCloseNoWallet}
          isOpened={isNoWalletModal}
        />
        <Modal
          title={t("invoiceNotCreated")}
          onClose={handleCloseError}
          isOpened={isErrorModal}
        />
      </>
    );
  }
  
