import { useTranslation } from "react-i18next";

import * as S from "./style";
import { useState } from "react";
import { IErrorResponse, IWalletTransaction } from "../../../models";
import axios, { AxiosError } from "axios";
import { TonProofMSApi } from "../../../TonProofMSApi";
import { sendTransactionToWallet } from "../../../utils";
import { useUserDomains } from "../../../hooks/useUserDomains";
import { useTonWallet } from "../../../hooks/useTonWallet";

export function assignBagToDomain() {
  const { t } = useTranslation("tools");
  // const [isTestnet, setIsTestnet] = useState(false);  // TODO: implement
  const [bagID, setBagID] = useState("");

  const wallet = useTonWallet();
  let userAddress = wallet ? wallet.account.address : "";

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const domains = useUserDomains({ userAddress, setError, setLoading });
  const [waitingWalletResponce, setWaitingWalletResponce] = useState(false);
  const [boc, setBoc] = useState("");

  const handleBagIDChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBagID(event.target.value);
  };

  const handleBagIDPaste = (event: React.ClipboardEvent) => {
    setBagID(event.clipboardData.getData("text"));
  };

  async function sendTransaction(txInfo: IWalletTransaction) {
    const onCompleted = (boc: string) => {
      setWaitingWalletResponce(false);
      setBoc(boc);
    };

    const onError = (error: string) => {
      setWaitingWalletResponce(false);
      setError(error);
    };
    
    setWaitingWalletResponce(true);

    sendTransactionToWallet([txInfo], onCompleted, onError);
  }

  async function getBagToDomainInfo(domain: string) {
    if (bagID.length === 0) {
      setError("Enter Bag ID first");
      return;
    }

    try {
      console.info("trying to load wallet domains...");
      setError("");
      setLoading(true);

      const response = await axios.get(`${TonProofMSApi.host}/dapp/getBagToDomainUrl?domain=${domain}&bagid=${bagID}`);
      setLoading(false);

      if (!response.data.result) throw "wrong response";

      sendTransaction(response.data.result);
    } catch (e) {
      setLoading(false);
      console.warn(`can not get tx info: ${(e as AxiosError).message}`);

      const err = e as AxiosError;
      if (err.response) {
        const data = err.response.data as IErrorResponse;
        console.log(data);
        if (data && data.message) {
          setError(data.message);
          return;
        }
      }

      setError("can not get url");
    }
  }

  if (!wallet) {
    return <p style={{ color: "red", textAlign: "center" }}>&nbsp;{ t("AuthenticationRequired") }</p>
  }
  
  return (
    <div
      style={{
        margin: "25px"
      }}>

      { error &&
        <p style={{ color: "red", textAlign: "center" }}>&nbsp;{ error ? error : "" }</p>
      }
{/* 
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          paddingBottom: "30px",
          cursor: "pointer",
        }}>
          <p style={{ color: "gray" }} >TESTNET</p>
          <p>&nbsp;|&nbsp;</p>
          <p style={{ color: "red", fontWeight: "bold" }} >MAINNET</p>  
      </div> */}

      <p>{ t("BagToDomain.EnterBagID") }:</p>
      <S.InputAddress 
        placeholder="91E887CB5CDB93039943CBA018ABB9F3E3C7939AA4B797EA5AD84BB6705C8D63" 
        onBlur={handleBagIDChanged} 
        onPaste={handleBagIDPaste} />

      {domains && (
        <div className="container py-2">
          <p>{ t("BagToDomain.ChooseDomain") }:</p>
          <br />
          {domains.map((domain, i) => (
            <>
              <div
                style = {{ 
                  padding: "0px",
                  margin: "0px",
                 }}
                key={i}
                onClick={() => {
                  getBagToDomainInfo(domain.dns);
                }}
              >
                <p
                  style={{
                    cursor: "pointer",
                    marginLeft: "20px",}}
                >&gt; {domain.dns}</p>
              </div>
              <br />
            </>
          ))}
        </div>
      )}

      { !domains && (
          <div>
            <p style={{ textAlign: "center" }}>{ t("BagToDomain.NoDomains") }</p>
          </div>
      )}

      {loading && <p className="text-xl text-center">⌛</p>}

      {waitingWalletResponce && (
        <>
          <p className="text-xl text-center">⌛</p>
          <p className="text-center">{ t("BagToDomain.AlmostDone") }</p>
        </>
      )}

      {boc && (
        <>
          <p className="text-xl text-center">✅</p>
          <p className="text-center">{ t("BagToDomain.CoolDomainShould") }</p>
          <br />
          <p className="text-right">
            { t("BagToDomain.ifSomethingWent") }&nbsp;
            <a className="text-red-600" href="https://t.me/tonbytesupport" target="_blank" rel="noreferrer">
              { t("BagToDomain.supportChat") }
            </a>
            )
          </p>
        </>
      )}
    </div>
  );
}
