import { styled } from "../../shared/lib/styles";

export const ToolsList = styled("div", {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    marginTop: "60px",
});


export const ToolContainer = styled("div", {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    margin: "10px",
    padding: "10px",
    border: "1px solid black", // #e5e7eb",
});
