import { styled } from "../../../shared/lib/styles";

export const InputAddress = styled("input", {
  width: "80%",
  height: "40px",
  borderRadius: "5px",
  border: "1px solid #f2f2f2",
  padding: "10px",
  margin: "10px 10px 20px 10px",
});
