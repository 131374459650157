import { TonProofItemReplySuccess } from "@tonconnect/protocol";
import { Account } from "@tonconnect/sdk";

import { connector } from "./connector";
import { IInvoice } from "./models";

class TonProofMSApiService {
  localStorageKey = "tonbyte-api-access-token";

  localStorageUserIDKey = "tonbyte-api-user-id";

  // host = "http://localhost:8081";
  host = "https://tonbyte.com";

  accessToken: string | null = null;

  userID: string | null = null;

  constructor() {
    this.accessToken = localStorage.getItem(this.localStorageKey);
    this.userID = localStorage.getItem(this.localStorageUserIDKey);
    console.info(`recover userID=${this.userID} accessToken=${this.accessToken}`);

    connector.onStatusChange((wallet) => {
      if (!wallet) {
        this.reset();
        return;
      }

      if (!wallet.connectItems) {
        return;
      }

      const { tonProof } = wallet.connectItems;

      if (tonProof) {
        if ("proof" in tonProof) {
          this.checkProof(tonProof.proof, wallet.account);
          return;
        }

        console.error(tonProof.error);
      }

      if (!this.accessToken) {
        connector.disconnect();
      }
    });
  }

  async generatePayload() {
    const response = await (
      await fetch(`${this.host}/ton-proof/generatePayload`, {
        method: "POST",
      })
    ).json();

    return response.payload as string;
  }

  async getBuyProductInvoice(productID: number, selectedNodes: number[], selectedPeriod: number): Promise<IInvoice | null> {
    try {
      console.info("call getBuyProductInvoice()");
      const requestBody = {
        user_id: parseInt(this.userID ? this.userID : "0", 10),
        auth_token: this.accessToken,
        product_id: productID,
        nodes: selectedNodes,
        period: selectedPeriod,
      };

      const response = await (
        await fetch(`${this.host}/ton-proof/buyProductInvoice`, {
          method: "POST",
          body: JSON.stringify(requestBody),
        })
      ).json();

      console.info("got check buyProduct result:", response);

      if (response && response.invoice) {
        return {
          StoreAddress: response.invoice.store_address,
          Cell: response.invoice.cell,
          Amount: response.invoice.amount,
          InvoiceID: response.invoice.invoice_id,
        }
      }

      return null;
    } catch (e) {
      console.log("checkBuyProduct error:", e);

      return null;
    }
  }

  async checkBuyProduct(productID: number, invoiceID: string): Promise<any> {
    try {
      console.info("call checkBuyProduct()");
      const requestBody = {
        user_id: parseInt(this.userID ? this.userID : "-1", 10),
        auth_token: this.accessToken,
        static_product_id: productID,
        invoice_id: invoiceID,
      };

      const response = await (
        await fetch(`${this.host}/ton-proof/checkBuyProduct`, {
          method: "POST",
          body: JSON.stringify(requestBody),
        })
      ).json();

      console.info("got check buyProduct result:", response);

      if (response && response.message) {
        return response.message;
      }

      return "Can not check your payment. Please ask support: t.me/tonbytesupport";
    } catch (e) {
      console.log("checkBuyProduct error:", e);

      return e as string;
    }
  }

  async checkProof(proof: TonProofItemReplySuccess["proof"], account: Account) {
    try {
      console.info("call checkProof()");
      const requestBody = {
        address: account.address,
        network: account.chain,
        proof: {
          ...proof,
          state_init: account.walletStateInit,
        },
      };

      const response = await (
        await fetch(`${this.host}/ton-proof/checkProof`, {
          method: "POST",
          body: JSON.stringify(requestBody),
        })
      ).json();

      console.info("check proof", response);
      if (response && response.token) {
        this.userID = response.userid;
        this.accessToken = response.token;
        localStorage.setItem(this.localStorageKey, response.token);
        localStorage.setItem(this.localStorageUserIDKey, response.userid);

        console.info("for user:", response.userid, "\ngot token:", response.token);
      }
    } catch (e) {
      console.log("checkProof error:", e);
    }
  }

  reset() {
    console.info("reset");
    this.accessToken = null;
    this.userID = null;
    localStorage.removeItem(this.localStorageKey);
    localStorage.removeItem(this.localStorageUserIDKey);
  }
}

export const TonProofMSApi = new TonProofMSApiService();
