import { useTranslation } from "react-i18next";

import { BagShortInfo, IErrorResponse } from "../../../models";
import FileUploadSingle from "./FileUploadSingle";
import { useState } from "react";
import { AddBagById } from "./AddBagById";

interface AddFileProps {
  userProductId: number;
  secret: string;
  addBag: (newBag: BagShortInfo) => void;
  maxSize: number;
}

export function AddFile({
  userProductId,
  secret,
  addBag,
  maxSize,
}: AddFileProps) {
  const { t } = useTranslation("account");
  const [isUploadById, setIsUploadById] = useState(false);

  return (
    <>
        { isUploadById && 
            <AddBagById
                userProductId={userProductId}
                secret={secret}
                addBag={addBag}
                />
        }

        { !isUploadById &&
            <FileUploadSingle
                userProductId={userProductId}
                secret={secret}
                addBag={addBag}
                maxSize={maxSize}
                />
        }

        <div
            style={{
              position: "fixed",
              left: 0,
              bottom: 20,
              width: "100%",
              textAlign: "center",
            }}>
            <p 
                onClick={() => { setIsUploadById(!isUploadById); }}
                style={{
                color: "#0044CC",
                cursor: "pointer",
                }}
                >{isUploadById ? t("uploadNew") : t("addByID")}</p>
        </div>
    </>
    );
}
