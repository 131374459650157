import { styled } from "../../shared/lib/styles";

export const Select = styled("div", {
  position: "relative",
});

export const Trigger = styled("button", {
  padding: 16,

  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
});

export const Content = styled("div", {
  position: "absolute",
  top: "100%",
  left: 0,
});

export const Option = styled("button", {
  padding: 16,

  backgroundColor: "$accent",
  border: "none",
  cursor: "pointer",
});
