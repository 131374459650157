import { useState } from "react";
import { IUserProduct } from "../../../models";
import { productSpace } from "../../../utils";

interface ProductItemProps {
    product: IUserProduct;
    selected: boolean;
    handleProductClick: (product: IUserProduct) => void;
}

export function ProductItem({ product, selected, handleProductClick }: ProductItemProps) {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid #e5e7eb",
        padding: "10px",
        margin: "5px",
        cursor: "pointer",
        backgroundColor: selected ? "#c2edff" : (isHover ? "#F5FCFF" : ""),
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => handleProductClick(product)}
    >
      <p>#{product.ID} {product.Title}</p>
      <p style={{ textAlign: "right" }}>{ productSpace(product, product.UsedSpace, true) }</p>
    </div>
  );
}
