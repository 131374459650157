import axios from "axios";
import { useEffect, useState } from "react";

import { IProductNodes as IProductNode } from "../models";
import { TonProofMSApi } from "../TonProofMSApi";

export function useProductNodes(productID: number) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [nodes, setNodes] = useState<IProductNode[]>([]);

  async function fetchNodes() {
    setError(null);
    setLoading(true);

    try {
      const response = await axios.get(
        `${TonProofMSApi.host}/dapp/getStoragesList/${productID}`
      );

      console.log(response.data.result);
      const nodesResponse = response.data.result as IProductNode[];
      if (!nodesResponse) {
        console.warn("got empty nodes");
        setLoading(false);
        return;
      }

      nodesResponse.sort((a: IProductNode) => {
        return a.can_be_unselected ? 1 : 0;
      });

      setNodes(nodesResponse);
      setLoading(false);

      console.info("got nodes", response.data);
    } catch (e: unknown) {
      console.warn("fetching nodes error:", e);
      setLoading(false);
      setError(e as string);
    }
  }

  useEffect(() => {
    fetchNodes();
  }, [productID]);

  return { loading, error, nodes };
}
