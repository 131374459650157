import { styled } from "../../../shared/lib/styles";

export const ContainerTitle = styled("div", {});

export const UploadFileContainer = styled("div", {
  width: "50%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: "4px",
});

export const InputBagID = styled("input", {
  width: "100%",
  height: "40px",
  borderRadius: "5px",
  border: "1px solid #f2f2f2",
  padding: "10px",
  margin: "10px 10px 20px 10px",
});

export const OwnBagIDContainer = styled("div", {
  width: "90%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  marginLeft: "auto",
  marginRight: "auto",
});
