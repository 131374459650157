import { styled } from "../../../shared/lib/styles";

export const Link = styled("a", {
  display: "block",

  overflow: "hidden",
  textOverflow: "ellipsis",

  "@laptop": {
    maxWidth: 350,
  },

  "@wideTablet": {
    maxWidth: 300,
  },

  "@tablet": {
    maxWidth: 200,
  },

  "@wideMobile": {
    maxWidth: 100,
  },
});

export const Actions = styled("div", {
  display: "flex",
  gap: 4,

  "@wideMobile": {
    flexWrap: "wrap",
  },
});
