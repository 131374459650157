import { useTranslation } from "react-i18next";

import * as S from "./style";
import { NodesList } from "../../components/NodesList/NodesList";
import { IProductDataStruct } from "../../models";
import { PeriodItemProps } from "../../components/PeriodsList/PeriodItem"
import { PeriodList } from "../../components/PeriodsList/PeriodList";
import { useEffect, useState } from "react";
import { priceTextInTon } from "../../utils";
import { useProductNodes } from "../../hooks/useProductLocations";
import { ListItemData } from "../../components/NodesList/NodeItem";

interface BuyProductPageProps {
    productID: number;
    dataStruct: IProductDataStruct;
    tonPrice: number;
    priceInCent: number;
    availableNodes: ListItemData[];
    setAvailableNodes: (nodes: ListItemData[]) => void;
    periods: PeriodItemProps[];
    setPeriods: (periods: PeriodItemProps[]) => void;
    buyClicked: () => void;
}

const hardcodedPeriods = [
    {
        months: 1,
        discount: 0,
        selected: false,
    },
    {
        months: 3,
        discount: 10,
        selected: false,
    },
    {
        months: 6,
        discount: 20,
        selected: true,
    },
    {
        months: 12,
        discount: 30,
        selected: false,
    },
] as PeriodItemProps[];

export function BuyProductPage(props: BuyProductPageProps) {
  const {loading: loadingNodes, error: errorNodes, nodes} = useProductNodes(props.productID);
  const [priceString, setPriceString] = useState<string>("");
  const { t } = useTranslation("configureProduct");

  const onNodeSelected = (index: number) => {
    const newNodes = props.availableNodes.map((item, i) => {
      return {
        ...item,
        selected: i === index ? !item.selected : item.selected,
      };
    });

    if (newNodes.filter((item) => item.selected).length > props.dataStruct.locations) {
      return;
    }

    props.setAvailableNodes(newNodes);
  };

  const onPeriodSelected = (index: number) => {
    const newPeriods = props.periods.map((item, i) => {
      return {
        ...item,
        selected: i === index,
      };
    })

    props.setPeriods(newPeriods);
  };

  // Update price string in result block
  useEffect(() => {
      const selectedPeriod = props.periods.find((item) => item.selected) as PeriodItemProps;
      if (!selectedPeriod) {
          return;
      }

      const price = props.priceInCent * selectedPeriod.months * (100 - selectedPeriod.discount) / 100;
      setPriceString(`$${price/100} ${priceTextInTon(price, props.tonPrice)}`);
  }, [props.periods]);

  // Refresh nodes list after loading
  useEffect(() => {
    const listNodes = nodes.map((item) => {
      return {
        id: item.id,
        title: item.name,
        selected: !item.can_be_unselected,
        disabled: !item.can_be_unselected,
        storageType: item.storage_type,
        } as ListItemData;
      });

      props.setAvailableNodes(listNodes);
  }, [nodes]);

  // Init periods
  useEffect(() => {
    props.setPeriods(hardcodedPeriods);
  }, []);

  return (
    <>
      <S.Container className="text-lg container place-content-center mx-auto lg:my-8">
        <S.Scrollable>
            <div className="py-2 px-4">
                  <S.TitleH2>{t("nodesHeader1")} {props.dataStruct.locations-1} {props.dataStruct.locations-1 > 1 ? (props.dataStruct.locations-1 < 5 ? t("node2") : t("node5")) : t("node1")}:</S.TitleH2>
                  <p className="text-gray-600">{t("nodesHint1")}</p>
                  <p className="text-gray-600">{t("nodesHint2")}</p>
                  <p className="text-gray-600">{t("nodesHint3")}</p>

                  {loadingNodes && <div>{t("loading")}</div>}
                  {errorNodes && <div>{t("loadingError")}: {errorNodes}</div>}
                  <NodesList nodes={props.availableNodes} onClick={onNodeSelected} />
                  
                  <br />

                  <S.TitleH2>{t("periodHeader")}:</S.TitleH2>
                  <PeriodList oneMonthPrice={props.priceInCent} 
                      toncoinPrice={props.tonPrice} 
                      periods={props.periods} 
                      onSelected={onPeriodSelected} />
            </div>

            <S.Hr className="m-4 " />
            
            <div className="border-2 rounded py-2 px-4 m-4">
                  <S.TitleH2>{t("result")}:</S.TitleH2>
                  <br />
                  <div className="px-4">
                    {props.dataStruct.features.map((feature, index) => {
                        return (
                            <p key={index}>
                                * {feature}
                            </p>
                        );
                    })}
                  </div>
                  <br />
                  <p><b>{t("total")}: {priceString}</b></p>
            </div>

            <S.BuyButton
              className="py-2 px-4 border-2 m-4"
              style={{ borderColor: "black", cursor: "pointer" }}
              onClick={props.buyClicked}>
              {t("buy")}
            </S.BuyButton>
        </S.Scrollable>
      </S.Container>
    </>
  );
}
