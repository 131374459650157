import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

import { addReturnStrategy, connector } from "../../connector";
import { useTonWallet } from "../../hooks/useTonWallet";
import { useTonWalletConnectionError } from "../../hooks/useTonWalletConnectionError";
import { useWallets } from "../../hooks/useWallets";
import { TonProofMSApi } from "../../TonProofMSApi";
import { isMobile, openLink } from "../../utils";
import { CloseButton } from "../CloseButton";
import { Modal } from "../Modal";
import * as S from "./style";
import { ModalWalletsProps } from "./types";
import { HorizontalLine } from "./ui/HorizontalLIne";

export const ModalWallets: FC<ModalWalletsProps> = ({ handleClose, isOpened }) => {
  const { wallets: walletsList } = useWallets();

  const [modalUniversalLink, setModalUniversalLink] = useState("");
  const wallet = useTonWallet();
  const onConnectErrorCallback = useCallback(() => {
    setModalUniversalLink("");

    // TODO: Show this to user
    console.log("Connection was rejected by user");
  }, []);
  useTonWalletConnectionError(onConnectErrorCallback);

  // const address = useSlicedAddress(wallet?.account.address);

  useEffect(() => {
    if (modalUniversalLink && wallet) {
      setModalUniversalLink("");
      handleClose();
    }
  }, [modalUniversalLink, wallet]);

  async function showQr(index: number) {
    TonProofMSApi.generatePayload()
      .then((payload) => {
        // Use loading screen/UI instead (while wallets list is loading)
        if (!walletsList.walletsList) {
          // eslint-disable-next-line no-use-before-define
          setTimeout(handleButtonClick, 200);
          return;
        }

        if (walletsList.embeddedWallet) {
          connector.connect({ jsBridgeKey: walletsList.embeddedWallet.jsBridgeKey }, { tonProof: payload });
          return;
        }

        const walletItem = walletsList.walletsList[index];

        console.log("user wallet:", walletsList.walletsList[index]);

        const tonkeeperConnectionSource =
          "universalLink" in walletItem
            ? {
                universalLink: walletItem.universalLink,
                bridgeUrl: walletItem.bridgeUrl,
              }
            : {
                jsBridgeKey: walletItem.jsBridgeKey,
              };

        console.info("connector:", connector);
        connector.onStatusChange((res) => {
          if (res && res.account.address) {
            handleClose();
          }
        });

        const universalLink = connector.connect(tonkeeperConnectionSource, {
          tonProof: payload,
        });

        if (!universalLink) {
          return;
        }

        if (isMobile()) {
          openLink(addReturnStrategy(universalLink, "none"), "_blank");
        } else {
          setModalUniversalLink(universalLink);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleButtonClick = useCallback(showQr, [walletsList.embeddedWallet, walletsList.walletsList]);

  const connectModalQr = (
    <S.WrapperQRModal>
      <Modal
        title="Scan QR-code with your wallet"
        onClose={() => {
          setModalUniversalLink("");
        }}
        isOpened={!!modalUniversalLink}
      >
        <S.WrapperCloseBtn>
          <CloseButton type="button" isActive theme="light" onClick={() => setModalUniversalLink("")} />
        </S.WrapperCloseBtn>
        <QRCode
          size={256}
          style={{ height: "260px", maxWidth: "100%", width: "100%" }}
          value={modalUniversalLink}
          viewBox="0 0 256 256"
        />
      </Modal>
    </S.WrapperQRModal>
  );

  // const { t } = useTranslation("other");

  useEffect(() => {
    if (isOpened) {
      document.body.style.overflow = "hidden";

      return;
    }

    document.body.style.overflow = "";
  }, [isOpened]);
  const { t } = useTranslation("modal");

  return (
    <>
      <S.ButtonWrapper>
        <CloseButton
          onClick={() => {
            console.log("close");
            handleClose();
          }}
          type="button"
          isActive={isOpened}
        />
      </S.ButtonWrapper>
      <S.ModalWallet isOpened={isOpened} onClick={handleClose}>
        <S.WrapperModal
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <S.WrapperInnerModal>
            <S.TitleModal>{t("PleaseLogIn")}</S.TitleModal>
            <S.TextModal>{t("YouMayContinue")}</S.TextModal>

            <HorizontalLine />

            <S.ListWallets>
              {walletsList.walletsList
                ? walletsList.walletsList.map((item, index) => (
                    <S.Wallet key={item.imageUrl}>
                      <S.Link onClick={() => handleButtonClick(index)}>
                        <S.Image src={item.imageUrl} alt={item.name} />
                        <S.NameWallet>{item.name}</S.NameWallet>
                      </S.Link>
                    </S.Wallet>
                  ))
                : null}
            </S.ListWallets>
            {connectModalQr}
            <HorizontalLine />
          </S.WrapperInnerModal>
        </S.WrapperModal>
      </S.ModalWallet>
    </>
  );
};
