import React, { FC } from "react";

import { useScrollBlock } from "../../shared/lib/hooks";
import * as S from "./style";
import { SmallModalProps } from "./types";

export const SmallModal: FC<SmallModalProps> = ({ children, title, onClose, isOpened }) => {
  const { allowScroll, blockScroll } = useScrollBlock();

  const onCloseHandler = () => {
    allowScroll();
    onClose();
  };

  if (isOpened) {
    blockScroll();
  } else {
    allowScroll();

    return null;
  }

  return (
    <>
      <S.Overlay className="fixed bg-black/50 top-0 right-0 left-0 bottom-0" onClick={onCloseHandler}>
          <S.WindowModal
            className="p-5 rounded bg-white absolute"
            onClick={(event) => event.stopPropagation()}
          >
            <h1 className={`text-2xl text-center${children ? " mb-2" : ""}`}>{title}</h1>

            {children}
          </S.WindowModal>
      </S.Overlay>
    </>
  );
};
