import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

const Languages = ["en", "ru"];

i18n
  .use(detector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,

    supportedLngs: Languages,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    }
  });
