import React from "react";

interface InfoContainerProps {
  children: React.ReactNode;
  title: string;
}

export function InfoContainer({ children, title }: InfoContainerProps) {
  return (
    <div className="py-2 px-4 m-4 rounded mb2 mx-auto">
      <h1>{title}</h1>
      <br />
      {children}
    </div>
  );
}
