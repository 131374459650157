import { styled } from "../../shared/lib/styles";

export const Overlay = styled("div", {
  display: "flex",
  alignItems: "center",

  zIndex: 500,

  cursor: "pointer",
});

export const WindowModal = styled("div", {
  cursor: "default",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 501,
  transform: "translate(70%, 50%)",
  width: "40%",
  height: "40%",

  "@wideTablet": {
    width: "60%",
  },

  "@tablet": {
    width: "60%",
  },

  "@wideMobile": {
    width: "80%",
  },

});
