
export interface ListItemData {
    id?: number;
    title: string;
    storageType: number;
    selected: boolean;
    disabled: boolean;
    onClick: () => void;
}

export function NodeItem({ title, selected, disabled, storageType: storageType, onClick }: ListItemData) {
  return (
    <div className={`items-center justify-between px-4
        ${ selected ? ' font-bold' : '' } 
        ${ disabled ? ' text-gray-600' : '' }`}
        onClick={ onClick }
        style={{
            cursor: 'pointer',
            }}>
        {selected ? "➕ " : "➖ "}
        {title}
        <b>{storageType === 1 ? "" : " 🌐"}</b>
    </div>
  );
}
