import { useTranslation } from "react-i18next";

import { SmallModal } from "../../SmallModal";
import { BagShortInfo } from "../../../models";
import { AddFile } from ".";

interface AddBagModalProps {
  onClose: () => void;
  isOpened: boolean;
  userProductId: number;
  userProductSecret: string;
  addBagHandler: (newBag: BagShortInfo) => void
  maxSize: number;
}

export function AddBagModal({onClose, isOpened, userProductId, userProductSecret, addBagHandler, maxSize}: AddBagModalProps) {
  const { t } = useTranslation("account");

  return (
    <SmallModal 
      title={t("addBagModalHeader")} 
      onClose={onClose} 
      isOpened={isOpened}>
        <div>
            <br />
            <center>
                <AddFile
                    userProductId={userProductId}
                    secret={userProductSecret}
                    addBag={addBagHandler}
                    maxSize={maxSize}
                />
            </center>
        </div>
    </SmallModal>
  );
}
