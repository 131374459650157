import { useTranslation } from "react-i18next";

import * as S from "../../../pages/ToolsPage/style";
import { useState } from "react";

interface ToolContainerProps {
  title: string;
  description: string;
  children: React.ReactNode;
  lockInfo?: string;
}

export function ToolContainer({title, description, children, lockInfo}: ToolContainerProps) {
  const { t } = useTranslation("tools");

  const [isExpanded, setIsExpanded] = useState(false);

  const showHideHandler = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    
    <S.ToolContainer style={{ backgroundColor: lockInfo ? "#f7f7f7" : "" }}>
      <b style={{ margin: "10px" }}>{title}</b>
      <div style={{ margin: "10px" }}>
        <p>{description}</p>
      </div>
      <button
        type="button"
        className="h-[35px] w-[200px] m-4 border-2"
        onClick={ lockInfo ? () => {} : showHideHandler }
      >
        { lockInfo && <b>{lockInfo}</b> }
        { !lockInfo && <b>{ isExpanded ? "Hide" : "Try" }</b> }
      </button>
      
      {isExpanded && children}  
    </S.ToolContainer>
  );
}
