import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";

import { IUserProduct } from "../models";
import { TonProofMSApi } from "../TonProofMSApi";
import { useLoggedIn } from "./useLoggedIn";

export function useUserProducts() {
  const loggedIn = useLoggedIn();
  const [loading, setLoading] = useState(false);
  const [userProducts, setUserProducts] = useState<IUserProduct[]>([]);
  const [tonPrice, setTonPrice] = useState<number>(0.0);

  async function fetchUserProductsList() {
    try {
      setLoading(true);
      const response = await axios.post(`${TonProofMSApi.host}/dapp/getUserProducts`, {
        user_id: parseInt(TonProofMSApi.userID ? TonProofMSApi.userID : "0", 10),
        auth_token: TonProofMSApi.accessToken,
      });

      setLoading(false);
      if (!response.data.products) {
        console.warn("can not parse fetched user products");
        return;
      }

      setTonPrice(response.data.tonprice);
      setUserProducts(response.data.products);
      console.info("got user products", response.data);
    } catch (e: unknown) {
      setLoading(false);
      console.warn("fetching user products error", (e as AxiosError).message);
    }
  }

  useEffect(() => {
    fetchUserProductsList();
  }, [loggedIn]);

  return { userProducts, tonPrice, loading };
}
