import { useState } from "react";
import { PeriodItem, PeriodItemProps } from "./PeriodItem";
import * as S from "./style";

interface PeriodListProps {
    toncoinPrice: number;
    oneMonthPrice: number;
    periods: PeriodItemProps[];
    onSelected: (index: number) => void;
}

export function PeriodList( { toncoinPrice, oneMonthPrice, periods, onSelected }: PeriodListProps ) {

    return (
        <S.ListPeriods>
            {periods.map((item, index) => (
                <PeriodItem key={index} 
                    oneMonthPrice={oneMonthPrice} 
                    toncoinPrice={toncoinPrice} 
                    onSelected={() => onSelected(index)} 
                    discount={item.discount}
                    months={item.months}
                    selected={item.selected} />
            ))}
        </S.ListPeriods>
    );
}
