import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useFilesSimplePagination } from "../../../hooks/useFilesSimplePagination";
import { BagShortInfo } from "../../../models";
import { UserFile } from "../UserFile";
import { AddBagModal } from "../AddFile/AddBagModal";

interface PaginatedFielsProps {
  userProductId: number;
  userProductSecret: string;
  onUploadFile?: (size: number) => void;
  onDeleteFile?: (size: number) => void;
  maxSize: number;
}

export function PaginationFiels({
  userProductId,
  userProductSecret,
  onDeleteFile,
  onUploadFile,
  maxSize,
}: PaginatedFielsProps) {
  const [isAddBagModal, setIsAddBagModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { loading, files, setFiles } = useFilesSimplePagination(
    userProductId,
    userProductSecret
  );
  const { t } = useTranslation("account");

  const addBagHandler = (newBag: BagShortInfo) => {
    var newFilesList = files;
    var alreadyExists = false;
    for (var i = 0; i < newFilesList.length; ++i) {
      if (newFilesList[i].BagID == newBag.BagID) {
        newFilesList[i].New = true;
        alreadyExists = true;
        break;
      }
    }
    
    if (!alreadyExists) {
      newFilesList = [...files, newBag];
    }

    setFiles(newFilesList);

    if (onUploadFile) {
      onUploadFile(newBag.Size);
    }
  };

  const removeBagHandler = (removedBagId: number) => {
    const file = files.find((item) => item.ID === removedBagId);

    const newFiles = files.filter(
      (fileInfo: BagShortInfo) => fileInfo.ID !== removedBagId
    );

    setFiles(newFiles);

    if (onDeleteFile && file) {
      onDeleteFile(file.Size);
    }
  };

  const showHideHandler = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <>
      { isAddBagModal &&
        <AddBagModal 
          onClose={() => { setIsAddBagModal(false); }}
          isOpened={isAddBagModal}
          userProductId={userProductId}
          userProductSecret={userProductSecret}
          addBagHandler={addBagHandler}
          maxSize={maxSize}
          ></AddBagModal>
      }
      <button
        type="button"
        className="h-[35px] w-[200px] m-4 border-2 rounded"
        onClick={showHideHandler}
      >
        <b>&nbsp;{isExpanded ? t("hideFiles") : t("showFiles")}&nbsp;</b>
      </button>

      {isExpanded && (
        <div
          style={{ marginInline: 30 }}
          >
          <center>
            <button 
              style={{ margin: "auto", padding: 5, width: "70%", border: "1px solid" }} 
              type="button" 
              onClick={() => { setIsAddBagModal(true); }}>
            add bag
            </button>
          </center>
          {loading ? (
            <p className="text-center">{t("Loading")}</p>
          ) : (
            <div>
              {!files.length && (
                <p className="text-center">
                  {t("YouHaveNoUploaded")}{" "}
                  {t("toSeeHow")}{" "}
                  <Link to="/docs" style={{
                    color: "red",
                  }}>
                    {t("CheckAPI")}
                  </Link>
                </p>
              )}
              {files && (
                <table className="table-auto" style={{ width: "100%", margin: 10 }}>
                  <thead>
                    <tr>
                      <th>{t("format")}</th>
                      <th>{t("bag")}</th>
                      <th>{t("size")}</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((file) => (
                      <UserFile
                        userProductId={userProductId}
                        userProductSecret={userProductSecret}
                        file={file}
                        key={file.ID}
                        removeBagCallback={removeBagHandler}
                      />
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
