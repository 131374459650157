import React from "react";
import { useTranslation } from "react-i18next";

import { InfoContainer } from "../../components/account/InfoContainer";
import { UserProductBlock } from "../../components/account/UserProductBlock";
// import { useSlicedAddress } from "../hooks/useSlicedAddress";
// import { useTonWallet } from "../hooks/useTonWallet";
import { useUserProducts } from "../../hooks/useUserProducts";
import { toUserFriendlyAddress } from "@tonconnect/sdk";
import { useTonWallet } from "../../hooks/useTonWallet";
// import { useSlicedAddress } from "../../hooks/useSlicedAddress";
// import { useTonWallet } from "../../hooks/useTonWallet";

export function AccountPage() {
  const wallet = useTonWallet();
  const { userProducts, tonPrice, loading } = useUserProducts();

  const { t } = useTranslation("account");
  const authorized = (
    <>
      <div
        style={{
          marginLeft: "20",
        }}>
        <br />
        {wallet && <p>{ t("hi") }, { toUserFriendlyAddress(wallet.account.address, false) }!</p>}
        <br />
      </div>
      <div className="justify-between">
        <InfoContainer title={t("YourProducts")}>
          {userProducts.map((product) => (
            <UserProductBlock
              product={product}
              tonPrice={tonPrice}
              key={product.ID}
            />
          ))}
          {!loading && userProducts.length === 0 && (
            <p className="text-center">{t("YouCanBuy")}</p>
          )}
          {loading && (
            <p className="text-xl text-center">{t("loadingProducts")}</p>
          )}
        </InfoContainer>
      </div>
    </>
  );

  const unauthorized = <h1>{t("connectYourWallet")}</h1>;

  return true ? authorized : unauthorized;
}
