import { styled } from "../../shared/lib/styles";

export const TitleDocs = styled("h1", {
  marginTop: "20px",
});

export const TitleH2 = styled("h2", {
  fontWeight: "600",

  "@laptop": {
    fontSize: "22px",
    lineHeight: "30px",
  },
  "@tablet": {
    fontSize: "20px",
  },
  "@wideMobile": {
    fontSize: "18px",
  },
});
