import { styled } from "../../shared/lib/styles";

export const TitleH2 = styled("h2", {
  fontWeight: "600",
  fontSize: "18px",
  lineHeight: "30px",
  
  "@laptop": {
    fontSize: "18px",
    lineHeight: "30px",
  },
  "@tablet": {
    fontSize: "18px",
  },
  "@wideMobile": {
    fontSize: "18px",
  },
});

export const ListPeriods = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",

  "@wideTablet": {
    gridTemplateColumns: "repeat(1, 1fr)",
  },

  "@tablet": {
    gridTemplateColumns: "repeat(1, 1fr)",
  },

  "@wideMobile": {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
});

export const PeriodItem = styled("div", {
  border: "2px solid #E5E5E5",
  borderRadius: "2px",
  margin: "10px 5px",
  padding: "15px",
  cursor: "pointer",
});
