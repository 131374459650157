import { useTranslation } from "react-i18next";

import * as S from "./style";
import { useState } from "react";
import { CollectionInfo, CommonContentInfo, NftInfo, NftInfoResponse, RoyaltyParams } from "../../../models";
import axios from "axios";
import { TonProofMSApi } from "../../../TonProofMSApi";
import { lineWithLink, tonStorageLink, tonscanLink } from "../../../utils";

interface NftInfoToolProps {
}

const MaxLen = 64;

export function NftInfoTool({}: NftInfoToolProps) {
  const { t } = useTranslation("tools");

  const [address, setAddress] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isTestnet, setIsTestnet] = useState(false);
  const [nftInfo, setNftInfo] = useState<NftInfo | null>(null);
  const [collectionInfo, setCollectionInfo] = useState<CollectionInfo | null>(null);
  const [itemsCodeType, setItemsCodeType] = useState<number>(-1);

  const handleCheckClick = () => {
    if (isLoading) {
      return;
    }

    if (address.length === 0) {
      setError("Address is empty");
      return;
    }

    if (address.length !== 48) {
      setError("Invalid address");
      return;
    }

    setError("");
    setIsLoading(true);
    setNftInfo(null);
    setCollectionInfo(null);
    setItemsCodeType(-1);

    var config = {
      params: {
        testnet: isTestnet,
      }
    }

    axios.get(`${TonProofMSApi.host}/dapp/tools/nftInfo/${address}`, config).then((response) => {
      console.log("nft info response:", response.data);
      if (!response.data.result) {
        setError("not found");
        return;
      }

      var result = response.data.result as NftInfoResponse;

      setNftInfo(result.nft.nft_address != "" ? result.nft : null);
      setCollectionInfo(result.collection.collection_address != "" ? result.collection : null);
      setItemsCodeType(result.items_code_type);
      setIsLoading(false);
    }).catch((error) => {
      console.log(error);
      if (error.response.data) {
        setError(error.response.data.message);
      } else {
        setError(error.message);
      }
      setIsLoading(false);
    });
  }

  const handleItemByIndex = (index: number) => {
    if (isLoading) {
      return;
    }

    if (index < 0) {
      return;
    }

    if (collectionInfo && collectionInfo.minted_items === index) {
      return;
    }

    if (address.length === 0) {
      setError("Address is empty");
      return;
    }

    if (address.length !== 48) {
      setError("Invalid address");
      return;
    }

    setError("");
    setIsLoading(true);
    setNftInfo(null);
    setCollectionInfo(null);
    setItemsCodeType(-1);

    var config = {
      params: {
        testnet: isTestnet,
      }
    }

    axios.get(`${TonProofMSApi.host}/dapp/tools/nftInfoByID/${address}/${index}`, config).then((response) => {
      console.log("nft info response:", response.data);
      if (!response.data.result) {
        setError("not found");
        return;
      }

      var result = response.data.result as NftInfoResponse;

      setNftInfo(result.nft.nft_address != "" ? result.nft : null);
      setCollectionInfo(result.collection.collection_address != "" ? result.collection : null);
      setItemsCodeType(result.items_code_type);
      setIsLoading(false);
    }).catch((error) => {
      console.log(error);
      if (error.response.data) {
        setError(error.response.data.message);
      } else {
        setError(error.message);
      }
      setIsLoading(false);
    });
  }

  const contentTypeToString = (contentType: number) => {
    switch (contentType) {
      case 0:
        return "Offchain";
      case 1:
        return "Onchain";
      default:
        return "Semichain";
    }
  }

  const handleAddressChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };

  const handleAddressPaste = (event: React.ClipboardEvent) => {
    setAddress(event.clipboardData.getData("text"));
  };

  return (
    <div
      style={{
        margin: "25px"
      }}>

      { error &&
        <p style={{ color: "red", textAlign: "center" }}>&nbsp;{ error ? error : "" }</p>
      }

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          paddingBottom: "30px",
          cursor: "pointer",
        }}
        onClick={() => {
          setIsTestnet((prevState) => !prevState);
        }}
        >
          <p style={{ color: isTestnet ? "#0070E0" : "black", fontWeight: isTestnet ? "bold" : "normal" }} >TESTNET</p>
          <p>&nbsp;|&nbsp;</p>
          <p style={{ color: !isTestnet ? "red" : "black", fontWeight: !isTestnet ? "bold" : "normal" }} >MAINNET</p>  
      </div>

      <p>{ t("NFTInfo.Address") }:</p>
      <S.InputAddress 
        placeholder="EQC6wQs45LshemXEjV0ONR4QP3PlMocIu4rMR5wl1rgteIdv" 
        onBlur={handleAddressChanged} 
        onPaste={handleAddressPaste} />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}>
        { nftInfo &&
          <div
            style={{
              border: "2px solid #e5e7eb",
              padding: "10px",
              marginRight: "10px",
              cursor: nftInfo.index > 0 ? "pointer" : "default",
              backgroundColor: nftInfo.index > 0 ? "" : "lightgrey",
            }}
            onClick={() => handleItemByIndex(nftInfo.index-1)}
            >
              <p style={{ textAlign: "center" }}>{ t("NFTInfo.Prev") }</p>
            </div>
        }
        <div 
          style={{
            border: "2px solid #e5e7eb",
            width: "30%",
            padding: "10px",
            cursor: "pointer",
          }}
          onClick={handleCheckClick}
          >
            <p style={{ textAlign: "center" }}>{ t("NFTInfo.Check") }</p>
        </div>
        { collectionInfo && nftInfo && 
          <div
            style={{
              border: "2px solid #e5e7eb",
              padding: "10px",
              marginLeft: "10px",
              cursor: collectionInfo && nftInfo && collectionInfo.minted_items > (nftInfo.index+1) ? "pointer" : "default",
              backgroundColor: collectionInfo && nftInfo && collectionInfo.minted_items > (nftInfo.index+1) ? "" : "lightgrey",
            }}
            onClick={() => handleItemByIndex(nftInfo.index+1)}
            >
              <p style={{ textAlign: "center" }}>{ t("NFTInfo.NextItem") }</p>
            </div>
        }
      </div>

      {
        isLoading && <>
          <br />
          <p style={{ textAlign: "center" }}>{ t("Loading") }</p>
        </>
      }

      { collectionInfo && (
        <>
          <br />
          <div style={{
            border: "2px solid #e5e7eb",
            padding: "10px",
          }}>
            {
              nftInfo && <>
                <p
                  style={{
                    fontSize: "16px",
                  }}>NFT:</p>
                <div
                  style={{
                    marginLeft: "14px",
                  }}>
                  { lineWithLink(t("Address"), nftInfo.nft_address, tonscanLink(nftInfo.nft_address, isTestnet), MaxLen) }
                  { lineWithLink(t("Owner"), nftInfo.owner_address, tonscanLink(nftInfo.owner_address, isTestnet), MaxLen) }
                  <p>{ t("NFTInfo.Index") }: {nftInfo.index}</p>
                  <p>{ t("NFTInfo.ContentURI") }: {nftInfo.content_url}</p>
                  <p>{ t("NFTInfo.ContentType") }: {contentTypeToString(nftInfo.content_type)}</p>
                </div>
                <br />
              </>
            }

            {
              collectionInfo && <>
              <p
                style={{
                  // textAlign: "center",
                  fontSize: "16px",
                }}>{ t("NFTInfo.Collection") }:</p>
                {/* Collection: */}
                <div
                  style={{
                    marginLeft: "14px",
                  }}>
                  { lineWithLink(t("Address"), collectionInfo.collection_address, tonscanLink(collectionInfo.collection_address, isTestnet), MaxLen)}
                  { lineWithLink(t("Owner"), collectionInfo.owner_address, tonscanLink(collectionInfo.owner_address, isTestnet), MaxLen) }
                  <p>{ t("NFTInfo.MintedItems") }: {collectionInfo.minted_items}</p>
                  { itemsCodeType !== -1 && <p>{ t("IsItemEditable") }: {itemsCodeType ? "true" : "false"}</p> }
                  <br />
                  <p>{ t("NFTInfo.ContentType") }: {contentTypeToString(collectionInfo.content_type)}</p>
                  { lineWithLink(t("NFTInfo.ContentURI"), collectionInfo.content_url, tonStorageLink(collectionInfo.content_url), MaxLen) }
                  { collectionInfo.common_content.content.length > 0 && lineWithLink(t("MintItems.CommonContent"), collectionInfo.common_content.content, tonStorageLink(collectionInfo.common_content.content), MaxLen) }
                  { collectionInfo.common_content.content.length === 0 && lineWithLink(t("MintItems.CommonContent"), "--", "", MaxLen) }
                  <br />
                  <p>{ t("NFTInfo.RoyaltyFactor") }: {collectionInfo.royalty.factor}</p>
                  <p>{ t("NFTInfo.RoyaltyBase") }: {collectionInfo.royalty.base}</p>
                  { lineWithLink(t("Address"), collectionInfo.royalty.address, tonscanLink(collectionInfo.royalty.address, isTestnet), MaxLen) }
                </div>
              </>
            }
          </div>
        </>
      )}
    </div>
  );
}
