import React, { FC } from "react";

import * as S from "./style";
import { CloseButtonProps } from "./types";

export const CloseButton: FC<CloseButtonProps> = ({ theme = "dark", isActive, children, onClick, type }) => (
  <S.CloseButton onClick={onClick} type={type} isActive={isActive} theme={theme}>
    {children}
  </S.CloseButton>
);
