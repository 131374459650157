import { useTranslation } from "react-i18next";
import * as S from "./style";
import { useState } from "react";
import { BagShortInfo, IErrorResponse } from "../../../models";
import axios, { AxiosError } from "axios";
import { TonProofMSApi } from "../../../TonProofMSApi";

interface AddBagByIdProps {
  userProductId: number;
  secret: string;
  addBag: (newBag: BagShortInfo) => void;
}

export function AddBagById({
    userProductId,
    secret,
    addBag
}: AddBagByIdProps) {
  const [error, setError] = useState("");
  const [bagID, setBagID] = useState("");
  const [processing, setIsProcessing] = useState(false);
  const [isCompleteSuccess, setIsCompleteSuccess] = useState(false);
  const { t } = useTranslation("account");
  
  const handleAddClick = async () => {
    if (bagID == "") {
        var errorText = t("bagIdRequired");
        setError(errorText);
        return;
    }
    if (bagID.length != 64) {
        var errorText = t("invalidBagId")
        setError(errorText);
        return;
    }

    setIsProcessing(true);
    const data = {
      user_product_id: userProductId,
      secret,
    };

    const config = {
      headers: {
        "content-type": "application/json",
      },
    }

    axios.post(`${TonProofMSApi.host}/storage/addBag/${bagID}`, data, config).then((response) => {
      console.log("add bag response:", response.data);
      if (!response.data.bags_info) {
        setError("not created");
        return;
      }

      if (response.data.bags_info.length === 0) {
        setError("not created");
        return;
      }

      for (let i = 0; i < response.data.bags_info.length; i += 1) {
        var newBag = response.data.bags_info[0].Bag;
        newBag.New = true;
        addBag(newBag);
      }

      setIsProcessing(false);
      setBagID("");
      setError("");
      setIsCompleteSuccess(true);
    }, (error) => {
      setIsProcessing(false);
      console.warn("add bag error:", error);

      const err = error as AxiosError;
      if (err) {
        console.info(err);
        if (err.response && err.response.data) {
          const data = err.response.data as IErrorResponse;
          if (data && data.message) {
            setError(data.message);
          } else {
            setError(JSON.stringify(data));
          }
        } else {
          setError(err.message);
        }
      }
    });
  };

  const updateBagID = (newBagID: string) => {
    setBagID(newBagID);
    setIsCompleteSuccess(false);
  };

  const handleBagIDChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateBagID(event.target.value);
  };

  const handleBagIDPaste = (event: React.ClipboardEvent) => {
    updateBagID(event.clipboardData.getData("text"));
  };

  return (
    <S.OwnBagIDContainer>
      <p style={{ color: "red", textAlign: "center" }}>{error ? error : ""}</p>
      <br />
      
      <p>{t("enterBagID")}:</p>
      <S.InputBagID 
        placeholder="D267F3028A2A214B9CB3F9253C9A3851A356AB0AB7B84C897B25CF3212813F4F" 
        onBlur={handleBagIDChanged} 
        onPaste={handleBagIDPaste} />

      <div style={{
          border: "2px solid lightgray",
          borderRadius: 3,
          width: "40%",
          padding: 10,
          margin: "auto",
          cursor: "pointer",
        }}
        onClick={handleAddClick}
        >
        <a>{t("findAndAdd")}</a>
      </div>

      { processing &&
        <>
          <p
            style={{
              fontSize: 40,
              marginTop: 5,
            }}
            >👀</p>
          <p>{t("lfabag")}</p>
          <p>{t("lfabagHint")}</p>
        </>
      }

      { isCompleteSuccess &&
        <>
          <p
            style={{
              fontSize: 24,
              marginTop: 20,
            }}
            >✅</p>
          <p>{t("fileIsFound")}</p>
          <p 
            style={{
                color: "gray",
            }}
            >({t("largeBagHint")})</p>
        </>
      }

    </S.OwnBagIDContainer>
  )
}
