import axios, { AxiosError } from "axios";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { BagShortInfo, IErrorResponse } from "../../../models";
import { TonProofMSApi } from "../../../TonProofMSApi";
import { bytesToSize } from "../../../utils";

interface FileUploadSingleProps {
  userProductId: number;
  secret: string;
  addBag: (newBag: BagShortInfo) => void;
  maxSize: number;
}

function FileUploadSingle({
  userProductId,
  secret,
  addBag,
  maxSize,
}: FileUploadSingleProps) {
  const [file, setFile] = useState<File | null>();
  const [progress, setProgress] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [isFolder, setIsFolder] = React.useState(false);
  const [error, setError] = useState<string | null>(null);
  const [fileSizeValid, setFileSizeValid] = useState(false);
  const { t } = useTranslation("account");

  const handleIsFolderChanged = () => {
    setIsFolder(!isFolder);
    setProgress(0);
    setError(null);
    setIsComplete(false);
  };

  const handleUploadClick = async () => {
    if (!file) {
      setError("choose file");
      return;
    }

    setProgress(1);
    setError(null);
    const formData = new FormData();
    formData.append(
      "json",
      JSON.stringify({
        user_product_id: userProductId,
        secret,
        is_folder: isFolder,
      })
    );
    formData.append("files[]", file);
    console.info(file.type);
    const config = {
      onUploadProgress: function(progressEvent: any) {
        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        if (percentCompleted > 1) {
          setProgress(percentCompleted);
        }
      },
      headers: {
        "content-type": file.type == "application/json" ? "documentJson" : file.type,
      },
    };
    axios.post(`${TonProofMSApi.host}/storage/addBag`, formData, config).then((response) => {
      console.log("add bag response:", response.data);
      if (!response.data.bags_info) {
        setError("not created");
        return;
      }

      if (response.data.bags_info.length === 0) {
        setError("not created");
        return;
      }

      for (let i = 0; i < response.data.bags_info.length; i += 1) {
        var newBag = response.data.bags_info[0].Bag;
        newBag.New = true;
        addBag(newBag);
      }

      setFile(undefined);
      setIsComplete(true);
    }, (error) => {
      console.warn("add bag error:", error);

      const err = error as AxiosError;
      if (err) {
        console.info(err);
        if (err.response && err.response.data) {
          const data = err.response.data as IErrorResponse;
          if (data && data.message) {
            setError(data.message);
          } else {
            setError(JSON.stringify(data));
          }
        } else {
          setError(err.message);
        }
      }
      setIsComplete(false);
    });
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files == null) {
      console.warn("handleFileInputChange() called with null files");
      return;
    }

    const file = event.target.files[0];
    if (file == null) {
      console.warn("handleFileInputChange() called with null file");
      return;
    }

    setFile(file);
    setError(null);
    setProgress(0);
    setFileSizeValid(file.size <= maxSize);
    setIsComplete(false);
    console.log("file size: ", file.size);
  }

  return (
    <>
      <p style={{ color: "red" }}>&nbsp;{error ? error : ""}</p>

      <b style={{
          marginTop: 5,
        }}>{maxSize > 0 ? bytesToSize(maxSize) + t("leftSpace") : t("noSpace")}</b>

      <p style={{
          marginTop: 15,
        }}><b>{t("file")}:</b> {file ? file.name : "-"}</p>
      <p style={{
        color: fileSizeValid ? "black" : "red"
        }}><b>{t("fileSize")}:</b> {file ? (!fileSizeValid ? t("tooHeavy") : bytesToSize(file.size)) : "-"}</p>
      <div style={{
          marginTop: 10,
        }}>
        <input type="checkbox" checked={isFolder} onChange={handleIsFolderChanged} />{" "}
        {t("isFolder")}{" "}
        <a
          style={{ color: "red" }}
          href={`${TonProofMSApi.host}/swagger/#/storage/add-userbag`}
          target="_blank"
          rel="noreferrer"
        >
          [?]
        </a>
        &nbsp;
      </div>

      <div style={{
        display: "flex",
      }}>
        <div style={{
          display: "flex",
          margin: "10px auto",
        }}>
          <div>
            <input
              id="file" 
              type="file" 
              style={{ display: "none" }}
              onChange={handleFileInputChange} 
              disabled={maxSize <= 0}/>
            <label htmlFor="file">
              <div style={{
                border: "2px solid lightgray",
                borderRadius: 3,
                padding: 10,
                margin: 5,
                cursor: "pointer",
              }}>
                <a>{ file ? t("chooseAnotherFile") : t("chooseFile")}</a>
              </div>
            </label>
          </div>
        
          { file && fileSizeValid &&
            <div style={{
                border: "2px solid lightgray",
                borderRadius: 3,
                padding: 10,
                margin: 5,
                cursor: "pointer",
              }}
              onClick={handleUploadClick}
              >
              <a>{t("upload")}</a>
            </div>
          }
        </div>
      </div>

      { progress > 0 && !isComplete &&
        <>
          <p
            style={{  
              textAlign: "center",
            }}
            >{t("uploading")}</p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0px 10px 0px",
            }}>
            <progress value={progress} max="100" />
          </div>
        </>
      }

      { progress == 100 && !error && isComplete &&
        <>
          <p
            style={{
              fontSize: 24,
            }}
            >✅</p>
          <p>{t("done")}</p>
        </>
      }
    </>
  );
}

export default FileUploadSingle;
