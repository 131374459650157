import { useState } from "react";
import { IDownloadingCollectionRequestInfo, IWalletTransaction } from "../../../models";
import { lineWithLink, sendTransactionToWallet, tonStorageLink, tonscanLink } from "../../../utils";
import axios from "axios";
import { TonProofMSApi } from "../../../TonProofMSApi";
import { useTranslation } from "react-i18next";

export interface RevertCollectionDataProps {
    requestInfo: IDownloadingCollectionRequestInfo;
    handleSuccessRevert: () => void;
}

const MaxLen = 40;

export function RevertCollectionData({ requestInfo, handleSuccessRevert }: RevertCollectionDataProps) {
    const { t } = useTranslation("tools");  

    const [error, setError] = useState("");
    const [isBuildingMessage, setIsBuildingMessage] = useState(false);
    const [confirmViaWallet, setConfirmViaWallet] = useState(false);
    const [isReverted, setIsReverted] = useState(false);

    function sendTransaction(txInfo: IWalletTransaction) {
        const onCompleted = (boc: string) => {
          setIsReverted(true);
          setConfirmViaWallet(false);
          handleSuccessRevert();
        };
    
        const onError = (error: string) => {
          setConfirmViaWallet(false);
          setError(error);
        };
        
        setConfirmViaWallet(true);
        sendTransactionToWallet([txInfo], onCompleted, onError);
    }

    const handleConfirmRevertClick = () => {
        if (!requestInfo) return;
        if (!requestInfo.additional_data.info) return;
    
        var data = {
            collection_meta_json: requestInfo.additional_data.info.old_collection_content_uri,
            collection_common_uri: requestInfo.additional_data.info.old_common_content_uri,
            royalty_address: requestInfo.additional_data.info.royalty_address,
            royalty_factor: requestInfo.additional_data.info.royalty_factor,
            royalty_base: requestInfo.additional_data.info.royalty_base,
          }
          
        setError("");
        setIsBuildingMessage(true);
        axios.post(`${TonProofMSApi.host}/dapp/tools/updateCollectionMeta/${requestInfo.collection_address}`, data).then((response) => {
          console.log("nft info response:", response.data);
          setIsBuildingMessage(false);
          if (!response.data.result) {
            setError("not found");
            return;
          }
    
          var transactionInfo = response.data.result as IWalletTransaction;
          sendTransaction(transactionInfo);
        }).catch((error) => {
          setIsBuildingMessage(false);
          console.log(error);
          if (error.response.data) {
            setError(error.response.data.message);
          } else {
            setError(error.message);
          }
        });
    }

    if (!requestInfo.additional_data.info) return (<></>)

    return (
        <div
            style={{
                paddingLeft: "10px",
                paddingRight: "10px",
            }}>
            { error && 
                <p style={{ textAlign: "center", color: "red" }}>{error}</p>
            }
            <p>{ t("TransferToStorage.RevertHint1") }</p>
            <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                <span
                    style={{
                        textAlign: "center",
                        color: "red",
                    }}
                    >⚠️ </span> { t("TransferToStorage.RevertHint2") }
                </p>
            <br />
            <p>{lineWithLink(t("TransferToStorage.OldCURI"), requestInfo.additional_data.info.old_collection_content_uri, tonStorageLink(requestInfo.additional_data.info.old_collection_content_uri), MaxLen)}</p>
            <p>{lineWithLink(t("TransferToStorage.OldCCURI"), requestInfo.additional_data.info.old_common_content_uri, tonStorageLink(requestInfo.additional_data.info.old_common_content_uri), MaxLen)}</p>
            
            { isReverted && 
                <>
                    <p style={{ textAlign: "center", marginTop: "30px" }}>{ t("Done") }!</p>
                    <p style={{ textAlign: "center" }}>({ t("TransferToStorage.RevertHint3") })</p>
                    <p style={{ textAlign: "center" }}>{lineWithLink("Collection address", requestInfo.collection_address, tonscanLink(requestInfo.collection_address, requestInfo.is_testnet), MaxLen)}</p>
                    <br />
                </>
            }
            { !isReverted &&
                <>
                    <p style={{ 
                        marginTop: "30px", 
                        textAlign: "center" }}>{ t("TransferToStorage.CollectionPrefix") }<b>{requestInfo.additional_data.collection_name}</b> { t("TransferToStorage.CollectionSuffix") } </p>
                    <div 
                        style={{
                            border: "2px solid #e5e7eb",
                            width: "30%",
                            marginLeft: "auto",
                            marginTop: "10px",
                            marginRight: "auto",
                            padding: "10px",
                            cursor: "pointer",
                        }}
                        onClick={handleConfirmRevertClick}
                        >
                        <p style={{ textAlign: "center" }}>{ confirmViaWallet ? t("CheckWallet") 
                                                                            : (isBuildingMessage ? t("BuildingMessage") 
                                                                                                : t("SendRevertTransaction")) }</p>
                    </div>
                    <br />
                </>
            }
        </div>
    );
}
