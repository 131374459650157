import { useTranslation } from "react-i18next";

import Product from "../../components/Product";
import { useProducts } from "../../hooks/useProducts";
import * as S from "./style";

export function ProductPage() {
  const { products, tonPrice } = useProducts();
  const { t } = useTranslation("common");

  return (
    <>
      <S.ContainerTitle className="text-xl min-h-300 container">
        <br />
        <S.TitleMain className="font-semibold text-center">{t("title")}</S.TitleMain>
        <S.Subtitle className="text-center">{t("subtitle")}</S.Subtitle>
        <S.BrTitle />
      </S.ContainerTitle>
      <S.Hr className="my-6 lg:my-8" />
      <S.FeaturesContainer className="text-lg container place-content-center mx-auto">
        <br />
        <S.Features className="text-2xl font-semibold" id="features">
          {t("features")}
        </S.Features>
        <br />
        <S.FileStorage className="font-bold">🖼 {t("filesStorage")}</S.FileStorage>
        <S.FileStorageText>
          {" "}
          {t("makeYourService")}{" "}
          <S.FileStorageLink className="text-red-600" href="/docs/#gateway">
            {" "}
            {t("readInDocumentation")}
          </S.FileStorageLink>{" "}
          {t("howApi")}
        </S.FileStorageText>
        <br />
        <S.GateWay className="font-bold">🌉 {t("GateWay")}</S.GateWay>
        <S.GateWayText>
          {t("canUse")}
          <S.GateWayLink className="text-red-600" href="/docs/#gateway">
            {" "}
            {t("ourApi")}
          </S.GateWayLink>{" "}
          {t("orAccountPage")}
        </S.GateWayText>
        <br />
        <S.TonSites className="font-bold">🗄 {t("tonSites")}</S.TonSites>
        <S.TonSitesText>
          {t("domain")}{" "}
          <S.TonSitesLink className="text-red-600" href="/docs#articles">
            {t("Articles")}
          </S.TonSitesLink>{" "}
          {t("inDocumentation")}
        </S.TonSitesText>
        <br />
        <S.TrueDecentralized className="font-bold">⚡ {t("trueDecentralized")}</S.TrueDecentralized>
        <S.TrueDecentralizedText>
          {t("weStore")}
        </S.TrueDecentralizedText>
        <br />
        <S.PrivateStorage className="font-bold text-gray-600">🔒 {t("PrivateStorage")}</S.PrivateStorage>
        <S.PrivateStorageText className="text-gray-600">{t("comingSoon")}</S.PrivateStorageText>
        <br />
      </S.FeaturesContainer>
      <S.Hr className="my-6 lg:my-8" />
      <S.PricesContainer className="min-h-300 container">
        <br />
        <S.PricesTitle className="text-2xl font-semibold" id="prices">
          {t("prices")}
        </S.PricesTitle>
        <br />
        <S.ListProduct className="flex justify-between">
          {products && products.length ? (
            products.map((product) => <Product product={product} tonPrice={tonPrice} key={product.ID} />)
          ) : (
            <S.LoadingProducts className="text-xl text-center">{t("loadingProducts")}</S.LoadingProducts>
          )}
        </S.ListProduct>
        <br />
      </S.PricesContainer>
    </>
  );
}
