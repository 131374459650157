import { sendTransaction } from "./connector";
import { HardcodedWalletInfo, IUserProduct, IWalletTransaction } from "./models";

export function isMobile(): boolean {
  return window.innerWidth <= 500;
}

export function openLink(href: string, target = "_self") {
  window.open(href, target, "noreferrer noopener");
}

export function titleWithHint(title: string, hint: string) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <p>{title} <span style={{ color: "gray" }}>{hint}</span></p>
    </div>
  );
};

export async function sendTransactionToWallet(txInfo: IWalletTransaction[], onCompleted: (boc: string) => void, onError: (error: string) => void) {
  if (!txInfo) {
    onError("transaction info not found. Something went wrong");
    return;
  }

  console.info("sending transaction to user's wallet");
  sendTransaction(
    {
      validUntil: Date.now() + 1000000,
      messages: txInfo.map((tx) => {
        return {
          address: tx.address,
          payload: tx.bin,
          amount: tx.amount,
        };
      }),
    },
    HardcodedWalletInfo,
  ).then((data) => {
    onCompleted(data.boc);
  }).catch((error) => {
    onError(error);
  });
}

export async function sendInitTransactionToWallet(txInfo: IWalletTransaction, onCompleted: (boc: string) => void, onError: (error: string) => void) {
  if (!txInfo) {
    onError("transaction info not found. Something went wrong");
    return;
  }

  console.info("sending transaction to user's wallet");
  sendTransaction(
    {
      validUntil: Date.now() + 1000000,
      messages: [
        {
          address: txInfo.address,
          stateInit: txInfo.state_init,
          amount: txInfo.amount,
        },
      ],
    },
    HardcodedWalletInfo,
  ).then((data) => {
    onCompleted(data.boc);
  }).catch((error) => {
    onError(error);
  });
}

export function tonStorageLink(address: string, isLocal = false) {
  if (address.indexOf("tonstorage://") === -1) {
    return address;
  }
  
  return address.replace("tonstorage://", isLocal ? "http://localhost:3001/gateway/" : "https://tonbyte.com/gateway/");
}

export function lineWithLink(prefix: string, text: string, link: string, maxLen: number) {
  var textCopy = text;
  if (text.length > maxLen + 3) {
    text = text.substring(0, maxLen / 2 - 2) + "..." + text.substring(text.length - maxLen / 2 + 1);
  }

  return (
    <div>
      { prefix.length > 0 && prefix + ": " }
      <a style={{ color: "#0070E0" }} href={link} target="_blank">{text}</a>
      <button
        type="button"
        title="copy Bag ID"
        onClick={() => {
          navigator.clipboard.writeText(textCopy);
        }}
      > &nbsp;
        { link.length > 0 && "📋" } 
      </button>
    </div>
  );
}

export function tonscanLink(address: string, isTestnet: boolean) {
  return (isTestnet ? "https://testnet.tonscan.org/address/" : "https://tonscan.org/address/") + address;
}

export function priceTextInTon(priceInCent: number, toncoinPrice: number): string {
  const priceInTon = priceInCent / 100 / toncoinPrice;

  return `≈ ${parseFloat(priceInTon.toFixed(4))} 💎`;
}

export function productSpace(product: IUserProduct, usedSpace: number, reverse = false) {
  const usedPrecent = usedSpace / (product.TotalSpace / 100);
  let progress = "";
  for (let i = 0; i < 20; i += 1) {
    progress += i / (20 / 100) < usedPrecent ? "#" : "-";
  }

  return (
    <>
      { reverse && 
        <p>{(usedSpace / 1024 / 1024).toFixed(2)}/{(product.TotalSpace / 1024 / 1024).toFixed(2)} Mb [{progress}]</p>
      }
      { !reverse &&
        <p>[{progress}] {(usedSpace / 1024 / 1024).toFixed(2)}/{(product.TotalSpace / 1024 / 1024).toFixed(2)} Mb{" "}</p>
      }
    </>
  );
}

export function bytesToSize(bytes: number) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  if (bytes === 0) {
    return "n/a";
  }

  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }

  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}
