import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IProductDataStruct } from "../models";
import { Modal } from "./Modal";
import { priceTextInTon } from "../utils";
import { PaymentModals } from "./PaymentModals";
import { BuyProductPage } from "../pages/BuyProductPage";
import { ListItemData } from "./NodesList/NodeItem";
import { PeriodItemProps } from "./PeriodsList/PeriodItem";

interface PayButtonProps {
  priceInCent: number;
  tonPrice: number;
  productID: number;
  dataStruct: IProductDataStruct;
}

export function PayButton({
  priceInCent,
  tonPrice,
  productID,
  dataStruct,
}: PayButtonProps) {
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const [showConfigureModal, setConfigureModal] = useState<boolean>(false);
  const [availableNodes, setAvailableNodes] = useState<ListItemData[]>([]);
  const [periods, setPeriods] = useState<PeriodItemProps[]>([]);
  const { t } = useTranslation("configureProduct");

  const selectedNodesIds = () : number[] => {
    return availableNodes.filter((item) => item.selected).map((item) => item.id? item.id : -1);
  };

  const selectedPeriod = () : number => {
    return periods.find((item) => item.selected)?.months || 1;
  };

  const closeConfigureModalHandler = () => {
    setConfigureModal(false);
  }

  const configureProductClicked = () => {
    setConfigureModal(true);
  }

  const closePaymentModalHandler = () => {
    setShowPaymentModal(false);
    closeConfigureModalHandler();
  };

  const buyClicked = () => {
    setShowPaymentModal(true);
  }

  const paymentModal = () => {
    const selectedNodes = selectedNodesIds();
    const selectedPeriodMonths = selectedPeriod();

    return ( 
      <PaymentModals 
        productID={productID} 
        selectedNodes={ selectedNodes } 
        selectedPeriod={ selectedPeriodMonths } 
        onClose={closePaymentModalHandler} />
    );
  }

  return (
    <>
      <Modal
        title={t("configure")}
        isOpened={showConfigureModal}
        onClose={closeConfigureModalHandler}
      >
        <BuyProductPage
          productID={productID} 
          dataStruct={dataStruct}
          priceInCent={priceInCent}
          tonPrice={tonPrice}
          availableNodes={availableNodes}
          setAvailableNodes={setAvailableNodes}
          periods={periods}
          setPeriods={setPeriods}
          buyClicked={buyClicked}
        />
      </Modal>

      { 
        showPaymentModal && paymentModal()
      }
      
      <button
        type="button"
        className="py-2 px-4 border-2 m-4"
        onClick={configureProductClicked}
      >
        ${priceInCent / 100}{" "}
        <span className="text-gray-600">
          {priceTextInTon(priceInCent, tonPrice)}
        </span>
      </button>
    </>
  );
}
