import { createStitches } from "@stitches/react";

import { setupStyles } from "./global";

export const media = {
  mobile: "only screen and (max-width: 461px)",
  wideMobile: "only screen and (max-width: 560px)",
  tablet: "only screen and (max-width: 768px)",
  wideTablet: "only screen and (max-width: 960px)",
  laptop: "only screen and (max-width: 1200px)",
};

export const { styled, getCssText, keyframes, createTheme, css } = createStitches({
  theme: {
    colors: {
      accent: "rgb(153, 230, 255)",
    },
  },
  media,
});

export const Container = styled("div", {});

export const setupGlobalStyles = () => {
  setupStyles();
};
