import { IProduct } from "../models";
import { PayButton } from "./Pay";

interface ProductProps {
  product: IProduct;
  tonPrice: number;
}

function Product({ product, tonPrice }: ProductProps) {
  // eslint-disable-next-line no-param-reassign
  product.DataStruct = JSON.parse(product.Data);
  
  return (
    <div className="border-2 py-2 px-4 m-4 rounded flex flex-col mb2">
      <h1 className="font-bold m-4 text-center">{product.Title}</h1>
      {product.DataStruct &&
        product.DataStruct.features.map((feature, index) => (
          <p className="text-right" key={index}>
            {feature}
          </p>
        ))}
      <PayButton
        priceInCent={product.PriceInCent}
        tonPrice={tonPrice}
        productID={product.ID}
        dataStruct={product.DataStruct}
      />
    </div>
  );
}

export default Product;
