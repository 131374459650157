import React, { useState } from "react";

interface SpoilerProps {
  showText: string;
  hideText: string;
  children: React.ReactNode;
}

export function Spoiler({ children, showText, hideText }: SpoilerProps) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div
        className="m-4"
        onClick={() => {
          setVisible(!visible);
        }}
      >
        <p className="text-red-600">{visible ? hideText : showText}</p>
      </div>

      {visible && <div className="px-4">{children}</div>}
    </>
  );
}
