import { useTranslation } from "react-i18next";

import * as S from "./style";
import { Container } from "../../shared/lib/styles"
import { useState } from "react";
import { ToolContainer } from "../../components/tools/ToolContainer";
import { NftInfoTool } from "../../components/tools/NftInfoTool";
import { NftCollectionUpdateTool } from "../../components/tools/NftCollectionUpdateTool";
import { assignBagToDomain } from "../../components/tools/AssignBagToDomain";
import { NftCollectionUpdateWHTool } from "../../components/tools/NftCollectionUpdateWHTool";
import { NftCreateCollectionTool } from "../../components/tools/NftCreateCollectionTool";
import { NftMintItemsTool } from "../../components/tools/NftMintItemsTool";
import { NftInfoByIndexTool } from "../../components/tools/NftInfoByIndexTool";
import { Airdrop } from "../../components/tools/Airdrop";

export function ToolsPage() {
  const { t } = useTranslation("tools");

  const GetAirdrop = () => {
    return (
      <Airdrop/>
    )
  }

  const getNftInfo = () => {
    return (
      <NftInfoTool/>
    );
  }

  const getNftByIndexInfo = () => {
    return (
      <NftInfoByIndexTool/>
    );
  }

  const updateCollectionMeta = () => {
    return (
      <NftCollectionUpdateWHTool/>
    );
  }

  const getNftCollectionUpdate = () => {
    return (
        <NftCollectionUpdateTool/>
    );
  }

  const getNftCreateCollectionTool = () => {
    return (
      <NftCreateCollectionTool/>
    );
  }

  const getNftMintItemsTool = () => {
    return (
      <NftMintItemsTool/>
    );
  }

  const authorized = (
    <Container>
      <S.ToolsList>

        {/* Edit */}
        <div
          style={{
            width: "90%",
            padding: "20px",
          }}>
          <p style={{
            fontSize: "16px",
          }}>{ t("Edit") }:</p>

          <ToolContainer
            title={ "✨ " + t("TransferToStorage.Title") }
            description={ t("TransferToStorage.Description") }
            children={ getNftCollectionUpdate() }/>
          <ToolContainer
            title={ "🔧 " + t("UPDMeta.Title") }
            description={ t("UPDMeta.Description") }
            children={ updateCollectionMeta() }/>
        </div>

        {/* Create */}
        <div
          style={{
            width: "90%",
            padding: "20px",
          }}>
          <p style={{
            fontSize: "16px",
          }}>{ t("Create") }:</p>

          <ToolContainer
            title={ "🌟 🏰 " + t("CreateCollection.Title") }
            description={ t("CreateCollection.Description") }
            children={ getNftCreateCollectionTool() }/>
          <ToolContainer
            title={ "🧙‍♂️ 💎" + t("MintItems.Title") }
            description={ t("MintItems.Description") }
            children={ getNftMintItemsTool() }/>
        </div>


        {/* Explore */}
        <div
          style={{
            width: "90%",
            padding: "20px",
          }}>
          <p style={{
            fontSize: "16px",
          }}>{ t("Explore") }:</p>

          <ToolContainer
            title={ "ℹ️ " + t("NFTInfo.Title") }
            description={ t("NFTInfo.Description") }
            children={ getNftInfo() }/>
          <ToolContainer
            title={ "ℹ️ " + t("NFTInfo.TitleByIndex") }
            description={ t("NFTInfo.DescriptionByIndex") }
            children={ getNftByIndexInfo() }/>
        </div>

        <p style={{
          marginTop: "10px",
          textAlign: "right",
          fontSize: "1.5rem",
        }}>{ t("Title2") }:</p>

        {/* Edit */}
        <div
          style={{
            width: "90%",
            padding: "20px",
          }}>
          <p style={{
            fontSize: "16px",
          }}>{ t("Edit") }:</p>

          <ToolContainer
            title={ "⚙️ " + t("BagToDomain.Title") } 
            description={ t("BagToDomain.Description") }
            children={ assignBagToDomain() }/>
        </div>

        <p style={{
          marginTop: "10px",
          textAlign: "right",
          fontSize: "1.5rem",
        }}>{ t("Title3") }:</p>

        <div
          style={{
            width: "90%",
            padding: "20px",
          }}>
          <p style={{
            fontSize: "16px",
          }}></p>

          <ToolContainer
            title={ "🚧 " + t("DownloadFromIPFS.Title") }
            description={ t("DownloadFromIPFS.Description") }
            children={ <></> }
            lockInfo={ `${t("Soon")}` }/>
        </div>
      </S.ToolsList>
    </Container>
  );

  const unauthorized = <h1>{t("connectYourWallet")}</h1>;

  return true ? authorized : unauthorized;
}
