import { useState } from "react";
import { IUserProduct } from "../../../models";
import { TonProofMSApi } from "../../../TonProofMSApi";
import axios from "axios";
import { ProductItem } from "./ProductItem";
import * as S from "./style";
import { useTranslation } from "react-i18next";

export interface newDownloadCollectionRequestProps {
    onGotDownloadRequestID: (requestID: number) => void;
    onGotError: (error: string) => void;
    userProducts: IUserProduct[];
}
  
export function NewDownloadCollectionRequest({ onGotDownloadRequestID, onGotError, userProducts }: newDownloadCollectionRequestProps) {
  const { t } = useTranslation("tools");  
  
  const [selectedProductID, setSelectedProductID] = useState(-1);
  const [address, setAddress] = useState("");
  const [isTestnet, setIsTestnet] = useState(false);
  const [isInfoLoading, setIsInfoLoading] = useState(false);
  const [isTonapiAllowed, setIsTonapiAllowed] = useState(true);

  const handleProductClick = (product: IUserProduct) => {
    setSelectedProductID(product.ID);
    onGotError("");
  }

  const handleUploadCollectionDataClick = () => {
    if (isInfoLoading) {
      return;
    }

    var selectedProduct = userProducts.filter((item) => item.ID === selectedProductID);
    if (selectedProduct.length == 0) {
      onGotError("Select product");
      return;
    }

    if (address.length === 0) {
      onGotError("Address is empty");
      return;
    }

    if (address.length !== 48) {
      onGotError("Invalid address");
      return;
    }

    onGotError("");
    setIsInfoLoading(true);

    var config = {
      params: {
        testnet: isTestnet,
        allow_tonapi: isTonapiAllowed,
      },
      headers: {
        "Content-Type": "application/json",
      }
    }

    var data = {
      user_id: parseInt(TonProofMSApi.userID ? TonProofMSApi.userID : "0", 10),
      user_product_id: selectedProduct[0].ID,
      secret: selectedProduct[0].Secret,
    }

    axios.post(`${TonProofMSApi.host}/dapp/tools/downloadCollectionMeta/${address}`, data, config).then((response) => {
      console.log("download collection meta response:", response.data);
      if (!response.data.result) {
        onGotError("not found");
        return;
      }

      var result = response.data.result as number;
      onGotDownloadRequestID(result);
    }).catch((error) => {
      console.log(error);
      if (error.response.data) {
        onGotError(error.response.data.message);
      } else {
        onGotError(error.message);
      }
    });
  };

  const handleAddressChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };

  const handleAddressPaste = (event: React.ClipboardEvent) => {
    setAddress(event.clipboardData.getData("text"));
  };

  return (
    <>
        <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            paddingBottom: "30px",
            cursor: "pointer",
        }}
        onClick={() => {
            setIsTestnet((prevState) => !prevState);
        }}
        >
            <p style={{ color: isTestnet ? "#0070E0" : "black", fontWeight: isTestnet ? "bold" : "normal" }} >TESTNET</p>
            <p>&nbsp;|&nbsp;</p>
            <p style={{ color: !isTestnet ? "red" : "black", fontWeight: !isTestnet ? "bold" : "normal" }} >MAINNET</p>  
        </div>

        <p>{ t("EnterCollectionAddress") }:</p>
        <S.InputAddress 
          placeholder="EQC6wQs45LshemXEjV0ONR4QP3PlMocIu4rMR5wl1rgteIdv" 
          onBlur={handleAddressChanged} 
          onPaste={handleAddressPaste} />
        
        <p>{ t("TransferToStorage.ChooseProduct") }:</p>
        <div
          style={{
              margin: "10px",
          }}>
          {userProducts.map((product) => (
              <ProductItem
              product={product}
              selected={product.ID === selectedProductID}
              key={product.ID}
              handleProductClick={ handleProductClick }/>
          ))}
        </div>

        <div>
          <br />
          <p>{ t("TransferToStorage.TonapiCacheInfo") }</p>
          <input
            style={{
              margin: "10px",
            }}
            type="checkbox"
            id="cbTonapi"
            checked={isTonapiAllowed}
            onChange={() => { setIsTonapiAllowed((prevState) => !prevState); } }
          />
          <label htmlFor="cbTonapi">{ t("TransferToStorage.TonapiCache") }</label>
        </div>

        <div 
            style={{
                border: "2px solid #e5e7eb",
                width: "40%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "30px",
                padding: "10px",
                cursor: "pointer",
            }}
            onClick={handleUploadCollectionDataClick}>
            <p style={{ textAlign: "center" }}>{ isInfoLoading ? "⏳" : t("TransferToStorage.UploadCollection") }</p>
        </div>
    </>
  )
}
