
import * as S from "./style";
import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";
import { bytesToSize, lineWithLink, sendTransactionToWallet, titleWithHint, tonStorageLink, tonscanLink } from "../../../utils";
import { IWalletTransaction, NftInfoResponse } from "../../../models";
import { TonProofMSApi } from "../../../TonProofMSApi";
import axios from "axios";
import { useTonWallet } from "../../../hooks/useTonWallet";

const MaxLen = 64;

export function NftMintItemsTool() {
  const { t } = useTranslation("tools");
  
  const wallet = useTonWallet();
  const [error, setError] = useState("");
  const [fileError, setFileError] = useState("");
  const [transactionError, setTransactionError] = useState("");
  const [isTestnet, setIsTestnet] = useState(false);
  
  const [collectionAddress, setCollectionAddress] = useState("");
  const [isCollectionInfoLoading, setIsCollectionInfoLoading] = useState(false);
  const [hasAddressInfo, setHasAddressInfo] = useState(false);
  
  const [ownerAddress, setOwnerAddress] = useState("");
  const [editorAddress, setEditorAddress] = useState("");
  const [address, setAddress] = useState("");
  const [commonContentURI, setCommonContentURI] = useState("");
  const [nextItemIndex, setNextItemIndex] = useState<number | null>(null);
  const [itemsCodeType, setItemsCodeType] = useState<number>(-1);
  const [isDelay, setIsDelay] = useState(false);

  const [file, setFile] = useState<File | null>();
  const [itemContentTemplate, setItemContentTemplate] = useState("");
  const [nftsCount, setNftsCount] = useState(1);
  const [isContentFromCSV, setIsContentFromCSV] = useState(false);
  const [maxNftsToMint, setMaxNftsToMint] = useState(0);

  const [isCompleted, setIsCompleted] = useState(false);  
  const [isBuildingMessage, setIsBuildingMessage] = useState(false);
  const [confirmViaWallet, setConfirmViaWallet] = useState(false);

  useEffect(() => {
    if (commonContentURI.endsWith("/"))
      setItemContentTemplate("$id/meta.json")
    else
      setItemContentTemplate("/$id/meta.json")
  }, [commonContentURI]);

  const handleNftsCountChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNftsCount(parseInt(event.target.value));
  };

  const handleTemplateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItemContentTemplate(event.target.value);
  };
  const handleTemplatePaste = (event: React.ClipboardEvent) => {
    setItemContentTemplate(event.clipboardData.getData("text"));
  };

  const handleAddressChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCollectionAddress(event.target.value);
  };
  const handleAddressPaste = (event: React.ClipboardEvent) => {
    setCollectionAddress(event.clipboardData.getData("text"));
  };

  const handleOwnerAddressChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOwnerAddress(event.target.value);
  };
  const handleOwnerAddressPaste = (event: React.ClipboardEvent) => {
    setOwnerAddress(event.clipboardData.getData("text"));
  };

  const handleEditorAddressChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditorAddress(event.target.value);
  };
  const handleEditorAddressPaste = (event: React.ClipboardEvent) => {
    setEditorAddress(event.clipboardData.getData("text"));
  };

  useEffect(() => {
    if (!wallet) return;

    setMaxNftsToMint(wallet.device.appName === "Tonkeeper" ? 50 : 200)
  }, [wallet]);  

  const handleLoadCollectionDataDelay = () => {
    if (isDelay) return;

    setIsDelay(true);
    setTimeout(() => {
      handleLoadCollectionData();
    }, 9000);
  }

  const handleLoadCollectionData = () => {
    setIsDelay(false);
    if (isCollectionInfoLoading) {
      return;
    }

    if (collectionAddress.length === 0) {
      setError("Address is empty");
      return;
    }

    if (collectionAddress.length !== 48) {
      setError("Invalid address");
      return;
    }

    setIsCompleted(false);
    setError("");
    setIsCollectionInfoLoading(true);
    setHasAddressInfo(false);

    var config = {
      params: {
        testnet: isTestnet,
      }
    }

    axios.get(`${TonProofMSApi.host}/dapp/tools/nftInfo/${collectionAddress}`, config).then((response) => {
      console.log("nft info response:", response.data);
      if (!response.data.result) {
        setError("not found");
        return;
      }

      var result = response.data.result as NftInfoResponse;
      
      setNextItemIndex(result.collection.minted_items);
      setCommonContentURI(result.collection.common_content.content);
      setItemsCodeType(result.items_code_type);
      setOwnerAddress(result.collection.owner_address);
      setEditorAddress(result.items_code_type === 1 ? result.collection.owner_address : "")
      setAddress(collectionAddress);
      setHasAddressInfo(true);
      setIsCollectionInfoLoading(false);
    }).catch((error) => {
      console.log(error);
      if (error.response.data) {
        setError(error.response.data.message);
      } else {
        setError(error.message);
      }
      setIsCollectionInfoLoading(false);
    });
  }

  function sendTransaction(txInfo: IWalletTransaction[]) {
    const onCompleted = (boc: string) => {
      setConfirmViaWallet(false);
      setIsCompleted(true);
    };

    const onError = (error: string) => {
      setConfirmViaWallet(false);
      setTransactionError(error);
    };
    
    setTransactionError("");
    setConfirmViaWallet(true);
    sendTransactionToWallet(txInfo, onCompleted, onError);
  }

  const buildMintItemsTranactionsFromCSV = () => {
    if (isCollectionInfoLoading) {
      return;
    }

    if (address.length !== 48) {
      setTransactionError("Invalid collection address");
      return;
    }

    if (ownerAddress.length !== 48) {
      setTransactionError("Invalid owner address");
      return;
    }

    if (itemsCodeType === -1 && editorAddress.length !== 48) {
      setTransactionError("Invalid editor address");
      return;
    }

    if (nftsCount < 1 || nftsCount > maxNftsToMint) {
      setTransactionError(`You can mint up to ${maxNftsToMint} items at once`);
      return;
    }

    if (file == null) {
      setTransactionError("File is empty");
      return;
    }

    var data = {
      owner_address: ownerAddress,
      collection_address: collectionAddress,
      common_content: commonContentURI,
      item_type: itemsCodeType,
      index_start_from: nextItemIndex,
      items_to_mint: nftsCount,
    }

    const formData = new FormData();
    formData.append(
      "json",
      JSON.stringify(data)
    );
    formData.append("files[]", file);

    var config = {
      headers: {
        "content-type": file.type == "application/json" ? "documentJson" : file.type,
      },
    }

    setIsBuildingMessage(true);

    axios.post(`${TonProofMSApi.host}/dapp/tools/mintNftItemsBatchByCSV`, formData, config).then((response) => {
      console.log("nft info response:", response.data);
      setIsBuildingMessage(false);
      if (!response.data.result) {
        setTransactionError("not found");
        return;
      }

      var transactionInfo = response.data.result as IWalletTransaction[];
      sendTransaction(transactionInfo);
    }).catch((error) => {
      setIsBuildingMessage(false);
      console.log(error);
      if (error.response.data) {
        setTransactionError(error.response.data.message);
      } else {
        setTransactionError(error.message);
      }
    });
  }

  const buildMintItemsTranactions = () => {
    if (isCollectionInfoLoading) {
      return;
    }

    if (address.length !== 48) {
      setTransactionError("Invalid collection address");
      return;
    }

    if (ownerAddress.length !== 48) {
      setTransactionError("Invalid owner address");
      return;
    }

    if (itemsCodeType === -1 && editorAddress.length !== 48) {
      setTransactionError("Invalid editor address");
      return;
    }

    if (nftsCount < 1 || nftsCount > maxNftsToMint) {
      setTransactionError(`You can mint up to ${maxNftsToMint} items at once`);
      return;
    }

    if (!commonContentURI.endsWith("/") && !itemContentTemplate.startsWith("/")) {
      setTransactionError("Item content template must start with /");
      return;
    }

    var data = {
      owner_address: ownerAddress,
      collection_address: collectionAddress,
      content_template: itemContentTemplate,
      item_type: itemsCodeType,
      index_start_from: nextItemIndex,
      items_to_mint: nftsCount,
    }

    setIsBuildingMessage(true);

    axios.post(`${TonProofMSApi.host}/dapp/tools/mintNftItemsBatch`, data, {}).then((response) => {
      console.log("nft info response:", response.data);
      setIsBuildingMessage(false);
      if (!response.data.result) {
        setTransactionError("not found");
        return;
      }

      var transactionInfo = response.data.result as IWalletTransaction[];
      sendTransaction(transactionInfo);
    }).catch((error) => {
      setIsBuildingMessage(false);
      console.log(error);
      if (error.response.data) {
        setTransactionError(error.response.data.message);
      } else {
        setTransactionError(error.message);
      }
    });
  }

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files == null) {
      console.warn("handleFileInputChange() called with null files");
      return;
    }

    const file = event.target.files[0];
    if (file == null) {
      console.warn("handleFileInputChange() called with null file");
      return;
    }

    if (file.size > 3 * 1024 * 1024) {
      setFileError("File size must be less than 3MB");
      return;
    }

    setFile(file);
    setFileError("");
    console.log("file size: ", file.size);
  }

  if (!wallet) {
    return <p style={{ color: "red", textAlign: "center" }}>&nbsp;{ t("AuthenticationRequired") }</p>
  }
  
  return (
    <div
      style={{
        margin: "25px"
      }}>

      { error &&
        <p style={{ color: "red", textAlign: "center" }}>&nbsp;{ error }</p>
      }

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          paddingBottom: "30px",
          cursor: "pointer",
        }}
        onClick={() => {
          setIsTestnet((prevState) => !prevState);
        }}
        >
          <p style={{ color: isTestnet ? "#0070E0" : "black", fontWeight: isTestnet ? "bold" : "normal" }} >TESTNET</p>
          <p>&nbsp;|&nbsp;</p>
          <p style={{ color: !isTestnet ? "red" : "black", fontWeight: !isTestnet ? "bold" : "normal" }} >MAINNET</p>  
      </div>

      <p>{ t("EnterCollectionAddress") }:</p>
      <S.InputAddress 
        placeholder="EQC6wQs45LshemXEjV0ONR4QP3PlMocIu4rMR5wl1rgteIdv" 
        onBlur={handleAddressChanged} 
        onPaste={handleAddressPaste} />

      { hasAddressInfo && 
        <>
          { lineWithLink(t("MintItems.CommonContent"), commonContentURI, tonStorageLink(commonContentURI), MaxLen) }
          { lineWithLink(t("MintItems.CollectionOwner"), ownerAddress, tonscanLink(ownerAddress, isTestnet), MaxLen) }
          <p>{ t("MintItems.NFTItemIndex") }: <i><b>{nextItemIndex}</b></i></p>
          { itemsCodeType !== -1 && <p>{ t("IsItemEditable") }: {itemsCodeType ? "true" : "false"}</p> }
        </>
      }

      <div 
        style={{
            border: "2px solid #e5e7eb",
            width: "30%",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "20px",
            padding: "10px",
            cursor: "pointer",
        }}
        onClick={hasAddressInfo ? handleLoadCollectionDataDelay : handleLoadCollectionData}
        >
        <p style={{ textAlign: "center" }}>{ isDelay ? "..." : isCollectionInfoLoading ? t("MintItems.Loading") 
                                                                                       : t("MintItems.LoadInfo") }</p>
      </div>
      
      { hasAddressInfo && !isCompleted &&
        <>
          <br />
          { transactionError &&
            <p style={{ color: "red", textAlign: "center" }}>&nbsp;{ transactionError }</p>
          }

          <br />
          <p>{ t("MintItems.ItemsOwner") }:</p>
          <S.InputAddress 
            defaultValue={ownerAddress}
            onBlur={handleOwnerAddressChanged} 
            onPaste={handleOwnerAddressPaste} />

          { itemsCodeType === 1 && editorAddress.length > 0 &&
            <>
            <p>{ t("MintItems.ItemsEditor") }:</p>
            <S.InputAddress 
              defaultValue={editorAddress}
              onBlur={handleEditorAddressChanged} 
              onPaste={handleEditorAddressPaste} />
            </>
          }

          <p>{ t("MintItems.ItemsContent") }:</p>
          <div
            style={{
              marginTop: "10px",
              border: "1px solid #e5e7eb",
              padding: "20px",
            }}>

            { !isContentFromCSV && 
              <>
                { titleWithHint(t("MintItems.ContentTemplate"), t("MintItems.ContentTemplateHint")) }
                <S.InputAddress 
                  defaultValue={itemContentTemplate}
                  onBlur={handleTemplateChanged} 
                  onPaste={handleTemplatePaste} />
              </>
            }

            {
              isContentFromCSV && nextItemIndex !== null &&
              <>
                { fileError &&
                  <p style={{ color: "red", textAlign: "center" }}>&nbsp;{ fileError }</p>
                }
                <p>{ t("MintItems.UploadCSVTitle") }:</p>
                <p style={{ marginLeft: "15px" }}>
                  <span style={{ color: "gray" }}>
                    {nextItemIndex},{ commonContentURI.endsWith("/") ? "" : "/" }content/lacroa_meta.json<br />
                    {nextItemIndex + 1},{ commonContentURI.endsWith("/") ? "" : "/" }content/legandary_pisha_meta.json<br />
                    {nextItemIndex + 2},{ commonContentURI.endsWith("/") ? "" : "/" }content/legendary_beckett_meta.json<br />
                    ...
                  </span>
                </p>

                <div>
                  <input
                    id="file" 
                    type="file" 
                    style={{ display: "none" }} 
                    onChange={handleFileInputChange}/>
                  <label htmlFor="file">
                    <div style={{
                      border: "2px solid lightgray",
                      borderRadius: 3,
                      padding: 10,
                      margin: 5,
                      cursor: "pointer",
                      width: "fit-content",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}>
                      <a>{ file ? t("MintItems.ChooseAnotherFile") : t("MintItems.ChooseFile") }</a>
                    </div>
                  </label>
                </div>

                { file &&
                  <p style={{ textAlign: "center" }}>
                    { t("MintItems.File") }: 
                    <span style={{ color: "gray" }}>
                    &nbsp;{file.name} 
                    </span>
                    &nbsp;({ bytesToSize(file.size) })
                  </p>
                }
                <br />
              </>
            }

            <p style={{
              textAlign: "center",
              cursor: "pointer",
              color: "#0070E0",
            }} 
              onClick={() => {
                setIsContentFromCSV((prevState) => !prevState);
              }}>{ isContentFromCSV ? t("MintItems.GenTemplate") : t("MintItems.UploadCSV") }</p>

          </div>
          <br />

          { titleWithHint(t("MintItems.NftsCount"), `${t("MintItems.NftsCountHint1")} ${maxNftsToMint} ${t("MintItems.NftsCountHint2")}`) }
          <S.InputAddress 
            placeholder={`${maxNftsToMint}`}
            onChange={handleNftsCountChanged} 
            value={nftsCount}
            type="number" />

          <div 
            style={{
                border: "2px solid #e5e7eb",
                width: "30%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "20px",
                padding: "10px",
                cursor: "pointer",
            }}
            onClick={() => { isContentFromCSV ? buildMintItemsTranactionsFromCSV() : buildMintItemsTranactions(); }}
            >
              <p style={{ textAlign: "center" }}>{ confirmViaWallet ? t("CheckWallet") 
                                                                  : (isBuildingMessage ? t("BuildingMessage") 
                                                                                      : t("SendTransaction")) }</p>
          </div>
        </>
      }

    </div>
  );
}
