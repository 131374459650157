import { styled } from "../../shared/lib/styles";

export const Container = styled("div", {});

export const TitleH2 = styled("h2", {
  fontWeight: "600",
  fontSize: "22px",
  lineHeight: "30px",
  
  "@laptop": {
    fontSize: "22px",
    lineHeight: "30px",
  },
  "@tablet": {
    fontSize: "20px",
  },
  "@wideMobile": {
    fontSize: "20px",
  },
});

export const Hr = styled("hr", {
  "@laptop": {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
});

export const Scrollable = styled("div", {
  overflow: "scroll",
  height: "650px",
});

export const BuyButton = styled("div", {
    border: "2px solid black",
    backgroundColor: "#F5FCFF",
    alignSelf: "center",
    textAlign: "center",
    width: "auto",
});
