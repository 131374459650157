import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IProduct } from "../models";
import { TonProofMSApi } from "../TonProofMSApi";

export function useProducts() {
  const [allProducts, setAllProducts] = useState<IProduct[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [tonPrice, setTonPrice] = useState<number>(0.0);
  const { i18n } = useTranslation();

  async function fetchProductsList() {
    try {
      const response = await axios.get(`${TonProofMSApi.host}/dapp/getProducts`);
      const {
        data: { products: data },
      } = response;
      if (!data) {
        console.warn("can not parse fetched products");

        return;
      }

      setTonPrice(response.data.tonprice);
      setAllProducts(data);
      const translationProducts = data.filter((obj: IProduct) => obj.Language === i18n.language);
      console.info("translationProducts", translationProducts);
      setProducts(translationProducts);
    } catch (e: unknown) {
      console.warn("fetching products error: ", (e as AxiosError).message);
    }
  }

  useEffect(() => {
    fetchProductsList();
  }, []);

  useEffect(() => {
    setProducts(allProducts.filter((obj: IProduct) => obj.Language === i18n.language));
  }, [allProducts, i18n.language]);

  return { products, tonPrice };
}
