import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import { useOutsideAlerter } from "../../shared/lib/hooks";
import * as S from "./style";
import { SelectProps } from "./types";

export const Select: FC<SelectProps> = ({ value, onChange, options, hideCurrentValue }) => {
  const [defaultValue, setDefaultValue] = useState<typeof value>(value || (options && options[0].value) || "");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const valueObject = useMemo(() => {
    if (!options) {
      return undefined;
    }

    return options.find((item) => item.value === defaultValue);
  }, [options, defaultValue]);

  const handleTriggerSelect = () => {
    setIsVisible((prevState) => !prevState);
  };

  const handleCloseSelect = () => {
    setIsVisible(false);
  };

  const handleChange = (currentValue: string) => {
    if (onChange) {
      onChange(currentValue);
    }

    handleCloseSelect();
  };

  useOutsideAlerter(containerRef, handleCloseSelect);

  useEffect(() => {
    setDefaultValue(value);
  }, [value]);

  return (
    <S.Select ref={containerRef}>
      <S.Trigger type="button" onClick={handleTriggerSelect}>
        {valueObject && valueObject.label}
      </S.Trigger>
      {isVisible && options && (
        <S.Content>
          {(hideCurrentValue ? options.filter((item) => item.value !== defaultValue) : options).map((item) => (
            <S.Option key={item.value} onClick={() => handleChange(item.value)}>
              {item.label}
            </S.Option>
          ))}
        </S.Content>
      )}
    </S.Select>
  );
};
