import "./i18n";

import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { App } from "./app";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Suspense fallback="">
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>,
);
