import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useLoggedIn } from "../../../../hooks/useLoggedIn";
import { LANGUAGES } from "../../../../shared/lib/constants";
import { ModalWallets } from "../../../ModalWallets";
import { MyButton } from "../../../MyButton";
import { Select } from "../../../Select";
import { NavigationProps } from "../../types";
import * as S from "./style";
import { CloseButtonWrapper } from "./style";

export const NavigationMobile: FC<NavigationProps> = ({ isOpened, handleClose }) => {
  const { t, i18n } = useTranslation("common");
  const loggedIn = useLoggedIn();
  const [isOpenedLogModal, setIsOpenedLogModal] = useState(false);

  useEffect(() => {
    if (isOpened) {
      document.body.style.overflow = "hidden";

      return;
    }

    document.body.style.overflow = "";
  }, [isOpened]);

  return (
    <>
      <S.SidebarOverlay isOpened={isOpened} onClick={() => handleClose()}>
        <CloseButtonWrapper onClick={handleClose} type="button" isActive={isOpened} />
        <S.Sidebar
          isActive={isOpened}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <S.SidebarInnerWrapper>
            <S.UserActions>
              <S.WrapperNavLink>
                <Link to="/tools" className="mr-6" onClick={handleClose}>
                  {t("tools")}
                </Link>
              </S.WrapperNavLink>
              <S.WrapperNavLink>
                <Link to="/docs" className="mr-6" onClick={handleClose}>
                  {t("API")}
                </Link>
              </S.WrapperNavLink>
              <S.WrapperSelectLang>
                <Select
                  options={LANGUAGES}
                  onChange={(value) => i18n.changeLanguage(value)}
                  value={i18n.language || LANGUAGES[0].value}
                  hideCurrentValue
                />
              </S.WrapperSelectLang>
            </S.UserActions>
            {loggedIn && (
              <span>
                |&nbsp;&nbsp;
                <Link to="/account" className="mr-6" onClick={handleClose}>
                  🧑‍💻 {t("ACCOUNT")}
                </Link>
              </span>
            )}
            {!loggedIn && (
              <S.ButtonConnectWrapper>
                <MyButton clicked={() => setIsOpenedLogModal(true)} text={t("connectWallet")} />
              </S.ButtonConnectWrapper>
            )}
          </S.SidebarInnerWrapper>
        </S.Sidebar>
      </S.SidebarOverlay>
      <ModalWallets
        handleClose={() => {
          setIsOpenedLogModal(false);
        }}
        isOpened={isOpenedLogModal}
      />
    </>
  );
};
