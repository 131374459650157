import React, { useState } from "react";
import { codepen, CopyBlock } from "react-code-blocks";
import { useTranslation } from "react-i18next";

import { IUserProduct } from "../../models";
import { Spoiler } from "../Spoiler";
import { PaginationFiels } from "./PaginationFiles";
import { priceTextInTon, productSpace } from "../../utils";

interface ProductProps {
  product: IUserProduct;
  tonPrice: number;
}

export function UserProductBlock({ product, tonPrice }: ProductProps) {
  const expiredAt = new Intl.DateTimeFormat("ru-RU", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }).format(product.ExpiredAt * 1000);
  const { t } = useTranslation("account");
  const [usedSpace, setUsedSpace] = useState<number>(product.UsedSpace);

  const onUploadFile = (size: number) => {
    setUsedSpace((prevState) => prevState + size);
  };

  const onDeleteFile = (size: number) => {
    setUsedSpace((prevState) => prevState - size);
  };

  return (
    <div className="border-2 py-2 px-4 m-4 rounded mb2 mx-auto" style={{ width: "95%" }}>
      <h1 className="m-4 font-bold text-left">
        {t("product.title")} {product.Title} #{product.ID}
      </h1>
      <p className="m-4 text-right">
        {t("product.price")} {priceTextInTon(product.PriceInCent, tonPrice)}
      </p>
      <p className="m-4 text-right">
        {t("product.until")} {expiredAt}
      </p>
      <p className="m-4 text-right">
        { productSpace(product, usedSpace) }
        {t("product.used")}
      </p>
      <PaginationFiels
        userProductId={product.ID}
        userProductSecret={product.Secret}
        onUploadFile={onUploadFile}
        onDeleteFile={onDeleteFile}
        maxSize={product.TotalSpace - usedSpace}
      />
      <Spoiler showText={t("product.secret")} hideText={t("product.secretHide")}>
        <CopyBlock lanshguage="url" text={product.Secret} showLineNumbers={false} theme={codepen} wrapLines codeBlock />
      </Spoiler>
    </div>
  );
}
