import { isWalletInfoInjected } from "@tonconnect/sdk";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";

import { connector } from "../connector";
import { WalletsListInfo } from "../models";

export function useWallets() {
  const [wallets, setWallets] = useState<WalletsListInfo>({});

  async function fetchWalletsList() {
    try {
      const walletsList = await connector.getWallets();
      const embeddedWallet = walletsList.filter(isWalletInfoInjected).find((wallet) => wallet.embedded);

      const walletsInfo: WalletsListInfo = { walletsList, embeddedWallet };
      setWallets(walletsInfo);

      console.log("got wallets:", walletsInfo);
    } catch (e: unknown) {
      console.warn("fetching wallets error", (e as AxiosError).message);
    }
  }

  useEffect(() => {
    fetchWalletsList();
  }, []);

  return { wallets };
}
