
import * as S from "./style";
import { useTranslation } from "react-i18next";

import { Dispatch, SetStateAction, useState } from "react";
import { lineWithLink, sendInitTransactionToWallet, titleWithHint, tonscanLink } from "../../../utils";
import axios from "axios";
import { TonProofMSApi } from "../../../TonProofMSApi";
import { IWalletTransaction } from "../../../models";
import { useTonWallet } from "../../../hooks/useTonWallet";

const MaxLen = 64;

export function NftCreateCollectionTool() {
  const { t } = useTranslation("tools");
  const wallet = useTonWallet();

  const [collectionAddressError, setCollectionAddressError] = useState("");
  const [collectionAddressRefreshing, setCollectionAddressRefreshing] = useState(false);
  const [collectionAddress, setCollectionAddress] = useState("");
  
  const [isCompleted, setIsCompleted] = useState(false);  
  const [isBuildingMessage, setIsBuildingMessage] = useState(false);
  const [confirmViaWallet, setConfirmViaWallet] = useState(false);

  const [error, setError] = useState("");
  const [isTestnet, setIsTestnet] = useState(false);
  // const [isItemsEditabe, setIsItemsEditabe] = useState(false);
  const [contentURI, setContentURI] = useState("");
  const [commonContentURI, setCommonContentURI] = useState("");
  const [ownerAddress, setOwnerAddress] = useState("");
  const [royaltyAddress, setRoyaltyAddress] = useState("");
  const [factor, setFactor] = useState<number | null>(10);

  const factorBase = 1000;

  // POST: /tools/computeCollectionAddress
  const onRefreshClick = () => {
    if (collectionAddressRefreshing) {
      return;
    }

    const data = {
      collection_content_uri: contentURI,
      common_content_uri: commonContentURI,
      owner_address: ownerAddress,
      royalty_address: royaltyAddress,
      royalty_factor: factor,
      royalty_base: factorBase,
      is_item_editable: false, // isItemsEditabe,
    }
    
    setCollectionAddressError("");
    setCollectionAddressRefreshing(true);

    axios.post(`${TonProofMSApi.host}/dapp/tools/computeCollectionAddress`, data).then((response) => {
      setCollectionAddressRefreshing(false);
      console.log("computeCollectionAddress response:", response.data);
      if (!response.data.result) {
        setCollectionAddress("");
        setCollectionAddressError("invalid data");
        return;
      }

      var result = response.data.result as string;
      setCollectionAddress(result);
    }).catch((error) => {
      setCollectionAddress("");
      setCollectionAddressRefreshing(false);
      console.log("computeCollectionAddress error:", error);
      if (error.response.data) {
        setCollectionAddressError(error.response.data.message);
      } else {
        setCollectionAddressError(error.message);
      }
    });
  }

  function sendTransaction(txInfo: IWalletTransaction) {
    const onCompleted = (boc: string) => {
      setConfirmViaWallet(false);
      setIsCompleted(true);
    };

    const onError = (error: string) => {
      setConfirmViaWallet(false);
      setError(error);
    };
    
    setConfirmViaWallet(true);
    sendInitTransactionToWallet(txInfo, onCompleted, onError);
  }

  const handleCreateCollection = () => {
    if (collectionAddressRefreshing) {
      return;
    }

    const data = {
      collection_content_uri: contentURI,
      common_content_uri: commonContentURI,
      owner_address: ownerAddress,
      royalty_address: royaltyAddress,
      royalty_factor: factor,
      royalty_base: factorBase,
      is_item_editable: false, // isItemsEditabe,
    }
    
    setError("");
    setIsBuildingMessage(true);

    axios.post(`${TonProofMSApi.host}/dapp/tools/createCollection`, data).then((response) => {
      setIsBuildingMessage(false);
      console.log("createCollection response:", response.data);
      if (!response.data.result) {
        setError("not found");
        return;
      }

      var transactionInfo = response.data.result as IWalletTransaction;
      setCollectionAddress(transactionInfo.address);
      sendTransaction(transactionInfo);
    }).catch((error) => {
      setIsBuildingMessage(false);
      console.log("createCollection error:", error);
      if (error.response.data) {
        setError(error.response.data.message);
      } else {
        setError(error.message);
      }
    });
  }

  const onChanged = (setAction: Dispatch<SetStateAction<string>>) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setAction(event.target.value);
    }
  };

  const onChangedNumber = (setAction: Dispatch<SetStateAction<number | null>>) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.replace(/\D/g, '');
      setAction(value.length > 0 ? Number(event.target.value.replace(/\D/g, '')) : null);
    }
  };

  const onPaste = (setAction: Dispatch<SetStateAction<string>>) => {
    return (event: React.ClipboardEvent) => {
      setAction(event.clipboardData.getData("text"));
    }
  };

  if (!wallet) {
    return <p style={{ color: "red", textAlign: "center" }}>&nbsp;{ t("AuthenticationRequired") }</p>
  }
  
  return (
    <div
      style={{
        margin: "25px"
      }}>

      { error &&
        <p style={{ color: "red", textAlign: "center" }}>&nbsp;{ error ? error : "" }</p>
      }

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          paddingBottom: "30px",
          cursor: "pointer",
        }}
        onClick={() => {
          setIsTestnet((prevState) => !prevState);
        }}
        >
          <p style={{ color: isTestnet ? "#0070E0" : "black", fontWeight: isTestnet ? "bold" : "normal" }} >TESTNET</p>
          <p>&nbsp;|&nbsp;</p>
          <p style={{ color: !isTestnet ? "red" : "black", fontWeight: !isTestnet ? "bold" : "normal" }} >MAINNET</p>  
      </div>

      <div style={{
        display: "flex",
      }}>
        <p>{ t("CreateCollection.YourCollectionAddress") }:</p>
        { collectionAddress && 
          <div style={{ marginLeft: "20px" }}>
            { lineWithLink("", collectionAddress, tonscanLink(collectionAddress, isTestnet), MaxLen) }
          </div>
        }
        <p 
          style={{ color: "blue", cursor: "pointer", marginLeft: "20px" }}
          onClick={onRefreshClick}
        >[<u>{ collectionAddressRefreshing ? "..." : t("Refresh") }</u>]</p>
        <p style={{ color: "red", marginLeft: "20px" }}>{collectionAddressError}</p>
      </div>
      <p></p>
      <p></p>

{/* 
      <input
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            marginRight: "10px",
          }}
          type="checkbox"
          id="cbItemsEditable"
          checked={isItemsEditabe}
          onChange={() => { setIsItemsEditabe((prevState) => !prevState); } }
        />
      <label htmlFor="cbItemsEditable">NFT items editable</label>*/}

      <br /> 

      { titleWithHint(t("CreateCollection.CollectionContent"), t("CreateCollection.CollectionContentHint")) }

      <S.InputAddress 
        placeholder="tonstorage://6514747CB744A22A8C57E51A740454EA61CD8601A1FCE9061F685728265E9382/collection.json" 
        onBlur={onChanged(setContentURI)} 
        onPaste={onPaste(setContentURI)} />

      { titleWithHint(t("CreateCollection.CommonContent"), t("CreateCollection.CommonContentHint")) }
      <S.InputAddress 
        placeholder="tonstorage://54AA477C686548F0BE57855BC3315CC58B79BA89458CC7D5EAA81F6DF2B1DFFE/" 
        onBlur={onChanged(setCommonContentURI)} 
        onPaste={onPaste(setCommonContentURI)} />

      { titleWithHint(t("Owner"), "") }
      <S.InputAddress 
        placeholder="kQAAgWbO3wOQ6z-NHSvvICav6MbefILGAEpMN4F9-8TxDj6b" 
        onBlur={onChanged(setOwnerAddress)} 
        onPaste={onPaste(setOwnerAddress)} />

      { titleWithHint(t("CreateCollection.RoyaltyAddress"), "") }
      <S.InputAddress 
        placeholder="kQAAgWbO3wOQ6z-NHSvvICav6MbefILGAEpMN4F9-8TxDj6b" 
        onBlur={onChanged(setRoyaltyAddress)} 
        onPaste={onPaste(setRoyaltyAddress)} />

      { titleWithHint(t("CreateCollection.RoyaltyFactor"), "") }
      <S.InputAddress 
        placeholder="100"
        onChange={onChangedNumber(setFactor)} 
        value={factor || ""}
        type="number" />

      <p>{ t("CreateCollection.CurrentRoyalty") }: {(factor ? factor/1000*100 : 0).toFixed(1)} %</p>

      <br />
      { !isCompleted && <div 
          style={{
              border: "2px solid #e5e7eb",
              width: "30%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "30px",
              padding: "10px",
              cursor: "pointer",
          }}
          onClick={handleCreateCollection}
          >
              <p style={{ textAlign: "center" }}>{ confirmViaWallet ? t("CheckWallet") 
                                                                  : (isBuildingMessage ? t("BuildingMessage")
                                                                                      : t("SendTransaction")) }</p>
          </div>
      }

      { isCompleted && 
        <>
          <br />
          <p style={{ textAlign: "center" }}>
            { t("CreateCollection.YourCollectionAddress") }: { lineWithLink("", collectionAddress, tonscanLink(collectionAddress, isTestnet), MaxLen) }
          </p>
          <br />
          <p style={{ textAlign: "center" }}>
            { t("CreateCollection.Hint1") } <b>ℹ️ { t("NFTInfo.Title") }</b>.
          </p>
        </>
      }
    </div>
  );
}
