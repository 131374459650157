import { styled } from "../../shared/lib/styles";

export const CloseButton = styled("button", {
  display: "none",
  alignItems: "center",
  position: "relative",
  border: "none",
  outline: "none",
  width: "28px",
  height: "28px",
  cursor: "pointer",

  "&::before": {
    content: "",
    position: "absolute",
    width: "100%",
    height: "3px",
    top: "12px",
    left: "0",
    borderRadius: "9999px",
    transform: "rotate(-45deg)",
    backgroundColor: "#000",
  },

  "&::after": {
    content: "",
    position: "absolute",
    width: "100%",
    height: "3px",
    bottom: "12px",
    left: "0",
    borderRadius: "9999px",
    transform: "rotate(45deg)",
    backgroundColor: "#000",
  },

  variants: {
    isActive: {
      true: {
        display: "flex",
      },
    },
    theme: {
      dark: {},
      light: {
        "&::before": {
          backgroundColor: "#fff",
        },

        "&::after": {
          backgroundColor: "#fff",
        },
      },
    },
  },
});
