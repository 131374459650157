import { WalletInfo, WalletInfoInjected } from "@tonconnect/sdk";

export const HardcodedWalletInfo: WalletInfo = {
  name: "Tonkeeper",
  imageUrl: "https://tonkeeper.com/assets/tonconnect-icon.png",
  aboutUrl: "https://tonkeeper.com",
  tondns: "tonkeeper.ton",
  bridgeUrl: "https://bridge.tonapi.io/bridge",
  universalLink: "https://app.tonkeeper.com/ton-connect",
  jsBridgeKey: "tonkeeper",
  injected: false,
  embedded: false,
};

export interface IInvoice {
  StoreAddress: string;
  Cell: string;
  Amount: number;
  InvoiceID: string;
}

export interface IProductDataStruct {
  features: string[];
  locations: number;
}

export interface IProduct {
  ID: number;
  Title: string;
  Data: string;
  DataStruct: IProductDataStruct;
  PriceInCent: number;
  Language: string;
}

export interface IUserProduct {
  ID: number;
  ProductID: number;
  Title: string;
  Data: string;
  Secret: string;
  PriceInCent: number;
  ExpiredAt: number;
  UsedSpace: number;
  TotalSpace: number;
}

export interface WalletsListInfo {
  walletsList?: WalletInfo[];
  embeddedWallet?: WalletInfoInjected;
}

export interface ITorrent {
  type: string;
  hash: string;
  flags: number;
  total_size: string;
  description: string;
  files_count: string;
  included_size: string;
  dir_name: string;
  downloaded_size: string;
  active_download: boolean;
  active_upload: boolean;
  completed: boolean;
  download_speed: number;
  upload_speed: number;
  fatal_error: string;
}

export interface IFile {
  type: string;
  name: string;
  size: string;
  priority: number;
  downloaded_size: string;
}

export interface IProductNodes {
  id: number;
  name: string;
  lattitude: number;
  longitude: number;
  can_be_unselected: boolean;
  selected: boolean;
  storage_type: number;
}

export interface IBagFullInfo {
  type: string;
  torrent: ITorrent;
  files: IFile[];
}

export interface IBagPeers {
  type: string;
  peers: IBagPeerInfo[];
  download_speed: number;
  upload_speed: number;
  total_parts: string;
}

export interface IBagPeerInfo {
  type: string;
  adnl_id: string;
  ip_str: string;
  download_speed: number;
  upload_speed: number;
  ready_parts: string;
}

export interface IBagInfoResponse {
  bag_info: IBagFullInfo;
  peers: IBagPeers;
}


export interface IErrorResponse {
  message: string;
  statusCode: number;
}

export interface BagShortInfo {
  ID: number;
  BagID: string;
  Format: string;
  Size: number;
  New: boolean;
}

export interface NftPreview {
  resolution: string;
  url: string;
}

export interface NftItem {
  address: string;
  dns: string;
  previews: NftPreview[];
}

export interface IWalletTransaction {
  address: string;
  bin: string;
  amount: string;
  state_init: string;
}

// Nft transfer tools:
export interface NftInfoResponse {
  nft: NftInfo;
  collection: CollectionInfo;
  items_code_type: number;
}

export interface RoyaltyParams {
  factor: number;
  base: number;
  address: string;
}

export interface CommonContentInfo {
  content: string;
  content_type: number;
}

export interface CollectionInfo {
  collection_address: string;
  owner_address: string;
  content_url: string;
  minted_items: number;
  content_type: number;
  royalty: RoyaltyParams;
  common_content: CommonContentInfo;
}

export interface NftInfo {
  nft_address: string;
  initialized: boolean;
  owner_address: string;
  index: number;
  content_url: string;
  content_type: number;
}

export interface NftDownloadInfo {
  collection_content_uri: string;
  old_collection_content_uri: string;
  common_content_uri: string;
  old_common_content_uri: string;
  royalty_address: string;
  royalty_factor: number;
  royalty_base: number;
  nft_offchain_content_uri: string;
}

export interface IDownloadingCollectionInfo {
  error: string;
  product: {
      id: number;
      title: string;
  };
  status: string;
  extra_info: string;
  progress: number;
  used_space: number;
  is_testnet: boolean;
  ready_to_update: boolean;
  collection_name: string;
  info?: NftDownloadInfo;
}

export interface IDownloadingCollectionRequestInfo {
  id: number;
  collection_address: string;
  state: number;
  is_testnet: boolean;
  additional_data: IDownloadingCollectionInfo;
  is_approved: boolean;
}
