
import { ListItemData, NodeItem } from './NodeItem';

interface ListData {
    nodes: ListItemData[];
    onClick: (index: number) => void;
}

export function NodesList({ nodes, onClick }: ListData) {
    return (
        <div>
            {nodes.map((node, index) => (
                <NodeItem key={index}
                    title={node.title}
                    disabled={node.disabled}
                    selected={node.selected}
                    storageType={node.storageType}
                    onClick={() => { if (!node.disabled) onClick(index); }}/>
            ))}
        </div>
    );
}
