import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useMedia } from "react-use";

import { media } from "../../shared/lib/styles";
import { Burger } from "../Burger";
import { NavigationDesktop } from "./media/desktop";
import { NavigationMobile } from "./media/mobile";
import * as S from "./style";

export function Navigation() {
  const isMobile = useMedia("only screen and (max-width: 901px)", false);
  const { t } = useTranslation("common");
  const [isOpened, setIsOpened] = useState(false);

  return isMobile ? (
    <>
      <S.WrapperTopNav>
        <Link to="/" className="font-bold">
          💎 {t("TONByteLogo")}
        </Link>
        <Burger isActive={isOpened} onCLick={() => setIsOpened(true)} />
      </S.WrapperTopNav>
      <NavigationMobile isOpened={isOpened} handleClose={() => setIsOpened(false)} />
    </>
  ) : (
    <NavigationDesktop />
  );
}
