import { useTranslation } from "react-i18next";

import * as S from "./style";
import { Dispatch, SetStateAction, useState } from "react";
import { CollectionInfo, IWalletTransaction, NftInfoResponse } from "../../../models";
import axios from "axios";
import { TonProofMSApi } from "../../../TonProofMSApi";
import { sendTransactionToWallet } from "../../../utils";
import { useTonWallet } from "../../../hooks/useTonWallet";

export function NftCollectionUpdateWHTool() {
  const { t } = useTranslation("tools");

  const wallet = useTonWallet();

  const [address, setAddress] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isTestnet, setIsTestnet] = useState(false);
  const [collectionInfo, setCollectionInfo] = useState<CollectionInfo | null>(null);
  const [isCompleted, setIsCompleted] = useState(false);  

  const [isBuildingMessage, setIsBuildingMessage] = useState(false);
  const [confirmViaWallet, setConfirmViaWallet] = useState(false);
  
  const [formError, setFormError] = useState("");
  const [collectionMetaURI, setCollectionMetaURI] = useState<string | null>(null);
  const [commonContentURI, setCommonContentURI] = useState<string | null>(null);
  const [royaltyAddress, setRoyaltyAddress] = useState<string | null>(null);
  const [royaltyFactor, setRoyaltyFactor] = useState<string | null>(null);
  const [royaltyBase, setRoyaltyBase] = useState<string | null>(null);

  const handleCheckClick = () => {
    if (isLoading) {
      return;
    }

    if (address.length === 0) {
      setError("Address is empty");
      return;
    }

    if (address.length !== 48) {
      setError("Invalid address");
      return;
    }

    setError("");
    setIsLoading(true);

    var config = {
      params: {
        testnet: isTestnet,
      }
    }

    setCollectionMetaURI(null);
    setCommonContentURI(null);
    setRoyaltyAddress(null);
    setRoyaltyFactor(null);
    setRoyaltyBase(null);
    setIsCompleted(false);
    axios.get(`${TonProofMSApi.host}/dapp/tools/nftInfo/${address}`, config).then((response) => {
      console.log("nft info response:", response.data);
      if (!response.data.result) {
        setError("not found");
        return;
      }

      var result = response.data.result as NftInfoResponse;

      setCollectionInfo(result.collection.collection_address != "" ? result.collection : null);
      setCollectionMetaURI(result.collection.content_url == "" ? null : result.collection.content_url);
      setCommonContentURI(result.collection.common_content.content == "" ? null : result.collection.common_content.content);
      setRoyaltyAddress(result.collection.royalty.address == "" ? null : result.collection.royalty.address);
      setRoyaltyFactor(result.collection.royalty.factor.toString() == "" ? null : result.collection.royalty.factor.toString());
      setRoyaltyBase(result.collection.royalty.base.toString() == "" ? null : result.collection.royalty.base.toString());

      setIsLoading(false);
    }).catch((error) => {
      console.log(error);
      if (error.response.data) {
        setError(error.response.data.message);
      } else {
        setError(error.message);
      }
      setIsLoading(false);
    });
  };

  const handleConfirmUpdateClick = () => {
    if (!collectionInfo) return;

    var data = {
        collection_meta_json: collectionMetaURI,
        collection_common_uri: commonContentURI,
        royalty_address: royaltyAddress,
        royalty_factor: Number(royaltyFactor),
        royalty_base: Number(royaltyBase),
      }
      
    setFormError("");
    setIsBuildingMessage(true);
    axios.post(`${TonProofMSApi.host}/dapp/tools/updateCollectionMeta/${collectionInfo.collection_address}`, data).then((response) => {
      console.log("nft info response:", response.data);
      setIsBuildingMessage(false);
      if (!response.data.result) {
        setFormError("not found");
        return;
      }

      var transactionInfo = response.data.result as IWalletTransaction;
      sendTransaction(transactionInfo);
    }).catch((error) => {
      setIsBuildingMessage(false);
      console.log(error);
      if (error.response.data) {
        setFormError(error.response.data.message);
      } else {
        setFormError(error.message);
      }
    });
  }

  function sendTransaction(txInfo: IWalletTransaction) {
    const onCompleted = (boc: string) => {
      setConfirmViaWallet(false);
      setIsCompleted(true);
    };

    const onError = (error: string) => {
      setConfirmViaWallet(false);
      setFormError(error);
    };
    
    setConfirmViaWallet(true);
    sendTransactionToWallet([txInfo], onCompleted, onError);
  }

  const handleAddressChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };

  const handleAddressPaste = (event: React.ClipboardEvent) => {
    setAddress(event.clipboardData.getData("text"));
  };

  const editableLine = (placeholder: string, title: string, value: string, oldValue: string, 
      onChange: Dispatch<SetStateAction<string | null>>, onPaste: Dispatch<SetStateAction<string | null>>) => {

    return (
      <>
        <p>{ title }:</p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <S.InputAddress
            placeholder={ placeholder }
            defaultValue={ value }
            onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => { onChange(event.target.value) } }
            onPaste={ (event: React.ClipboardEvent) => { onPaste(event.clipboardData.getData("text")) } }/>
          {/* <p
            style={{
              cursor: "pointer",
              marginLeft: "10px",
            }}
            onClick={() => { 
              console.info("revert", oldValue);
              onChange(oldValue);
             }}
            >[revert]</p> */}
        </div>
      </>
    );
  }

  if (!wallet) {
    return <p style={{ color: "red", textAlign: "center" }}>&nbsp;{ t("AuthenticationRequired") }</p>
  }
  
  return (
    <div
      style={{
        margin: "25px"
      }}>

      { error &&
        <p style={{ color: "red", textAlign: "center" }}>&nbsp;{ error ? error : "" }</p>
      }

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          paddingBottom: "30px",
          cursor: "pointer",
        }}
        onClick={() => {
          setIsTestnet((prevState) => !prevState);
        }}
        >
          <p style={{ color: isTestnet ? "#0070E0" : "black", fontWeight: isTestnet ? "bold" : "normal" }} >TESTNET</p>
          <p>&nbsp;|&nbsp;</p>
          <p style={{ color: !isTestnet ? "red" : "black", fontWeight: !isTestnet ? "bold" : "normal" }} >MAINNET</p>  
      </div>

      <p>{ t("UPDMeta.Address") }</p>
      <S.InputAddress 
        placeholder="EQC6wQs45LshemXEjV0ONR4QP3PlMocIu4rMR5wl1rgteIdv" 
        onBlur={handleAddressChanged} 
        onPaste={handleAddressPaste} />

      <div 
        style={{
          border: "2px solid #e5e7eb",
          width: "40%",
          margin: "auto",
          padding: "10px",
          cursor: "pointer",
        }}
        onClick={handleCheckClick}
        >
          <p style={{ textAlign: "center" }}>{ isLoading ? t("UPDMeta.LookingData") : (collectionInfo ? t("UPDMeta.LoadAgain") : t("UPDMeta.LoadData")) }</p>
      </div>

      { collectionInfo && collectionMetaURI && royaltyAddress && royaltyFactor && royaltyBase && (
        <>
          <br />
          <hr />
          <br />
          { formError && <p style={{ color: "red", textAlign: "center" }}>{ formError }</p> }
          <p style={{ textAlign: "center" }}>{ t("UPDMeta.UpdateTitle") }:</p>
          <br />
          {
            editableLine("tonstorage://CF7E486C9D738BF9DB1C7249EDEF43FA4004ADE45EE5F91E5ED8327512B74943/collection.json", t("UPDMeta.CollectionContent"), 
              collectionMetaURI, collectionInfo.content_url, setCollectionMetaURI, setCollectionMetaURI)
          }
          { !commonContentURI && 
            <p
              style={{
                color: "orange",
              }}>{ t("UPDMeta.HintEmptyCommonContent") }</p> 
          }
          {
            editableLine("tonstorage://736DE4DC5D4EF2E485D66BB4A3289749EFC482E9F615303771C39D69E03225BC", t("UPDMeta.CommonContent"),
              commonContentURI ? commonContentURI : "", collectionInfo.common_content.content, setCommonContentURI, setCommonContentURI)
          }
          {
            editableLine("EQDMTFLVEHrYssm48g9XwjiqQ0j60SoZ4uGn6SOR5-WTgr9j", t("UPDMeta.RoyaltyAddress"),
              royaltyAddress, collectionInfo.royalty.address, setRoyaltyAddress, setRoyaltyAddress)
          }
          {
            editableLine("161", t("UPDMeta.RoyaltyFactor"),
              royaltyFactor, collectionInfo.royalty.factor.toString(), setRoyaltyFactor, setRoyaltyFactor)
          }
          {
            editableLine("1000", t("UPDMeta.RoyaltyBase"),
              royaltyBase, collectionInfo.royalty.base.toString(), setRoyaltyBase, setRoyaltyBase)
          }

          {/* Warning message */}
          <br />
          <p>
            ⚠️ { t("UPDMeta.Warning") }
          </p>

          { !isCompleted && <div 
              style={{
                  border: "2px solid #e5e7eb",
                  width: "30%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "30px",
                  padding: "10px",
                  cursor: "pointer",
              }}
              onClick={handleConfirmUpdateClick}
              >
                  <p style={{ textAlign: "center" }}>{ confirmViaWallet ? t("CheckWallet") 
                                                                      : (isBuildingMessage ? t("BuildingMessage") 
                                                                                          : t("SendUpdate")) }</p>
              </div>
          }

          { isCompleted && 
            <>
              <br />
              <p style={{ textAlign: "center" }}>
                { t("Done") }
              </p>
              <p style={{ textAlign: "center" }}>
                { t("UPDMeta.CheckMeta") } <b>ℹ️ { t("NFTInfo.Title") }</b>.
              </p>
            </>
          }
        </>
      )}
    </div>
  );
}
