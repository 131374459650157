import { useEffect, useState } from "react";

import { TonProofMSApi } from "../TonProofMSApi";
import { useTonWallet } from "./useTonWallet";

export function useLoggedIn() {
  const wallet = useTonWallet();
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    console.info("logged in: ", wallet, TonProofMSApi.accessToken);
    setLoggedIn(!!wallet);
  }, [wallet]);

  return loggedIn;
}
